import React from 'react'
import { Link } from 'react-router-dom'

const Accordion1 = () => (
    <p>Prezado Cliente, É com grande satisfação que informamos que nosso aplicativo encontra-se disponível para download na <span className='bold'>Apple Store e Google Play.</span> Estamos comprometidos em oferecer uma experiência de usuário de alta qualidade em dispositivos iOS e Android.</p>
)

const Accordion2 = () => (
    <p>Gostaríamos de salientar que nossa plataforma é exclusivamente desenvolvida para <span className='bold'>iPhones e Smartphones.</span> Nosso sistema é otimizado para dispositivos mais recentes, garantindo desempenho e funcionalidades ideais.</p>
)

const Accordion3 = () => (
    <p>É importante observar que nossa plataforma oferece suporte a dispositivos lançados nos <span className='bold'>últimos cinco anos.</span> Após esse período, não podemos garantir o pleno funcionamento do aplicativo, e não nos responsabilizamos por eventuais problemas decorrentes do uso em dispositivos mais antigos.</p>
)

const Accordion4 = () => (
    <p>Destacamos que não oferecemos suporte ou nos responsabilizamos por questões relacionadas ao funcionamento do aplicativo em dispositivos fora do período de compatibilidade mencionado anteriormente. É crucial que essa informação seja levada a sério para garantir a melhor experiência possível.</p>
)

const Accordion5 = () => (
    <p>Estamos constantemente trabalhando para aprimorar nossa plataforma. Este documento representa a versão inicial das informações sobre compatibilidade, e nos comprometemos a atualizá-lo conforme novas versões do aplicativo forem lançadas. Recomendamos que os usuários consultem regularmente a documentação para obter informações atualizadas.
        <br/><br/>Agradecemos por escolher nosso aplicativo e esperamos que essa descrição forneça clareza sobre a compatibilidade e suporte oferecidos. Estamos à disposição para esclarecer qualquer dúvida adicional.</p>
)

const Accordion6 = () => (
    <p>
        <Link to="/doc/overview/tasks">
            Organizar tarefas
        </Link><br/><br/>
        <Link to="/doc/overview/finance">
            Minhas finanças
        </Link><br/><br/>
        <Link to="/doc/overview/budgets">
            Orçamentos e compras
        </Link><br/><br/>
        <Link to="/doc/overview/calculator">
            Calculadora de juros
        </Link>
    </p>
)

export { Accordion1, Accordion2, Accordion3, Accordion4, Accordion5, Accordion6 }