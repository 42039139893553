// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-folder {
    display: flex;
    margin-top: 50px;
}

.folder {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.folder img {
    width: 30%;
    opacity: 0.2;
    /* cursor: pointer; */
}

.folder p {
   color: black; 
   opacity: 0.2;
   /* cursor: pointer; */
}`, "",{"version":3,"sources":["webpack://./src/source/Documentation/doc/sectors/Partner/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,qBAAqB;AACzB;;AAEA;GACG,YAAY;GACZ,YAAY;GACZ,qBAAqB;AACxB","sourcesContent":[".container-folder {\n    display: flex;\n    margin-top: 50px;\n}\n\n.folder {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.folder img {\n    width: 30%;\n    opacity: 0.2;\n    /* cursor: pointer; */\n}\n\n.folder p {\n   color: black; \n   opacity: 0.2;\n   /* cursor: pointer; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
