import React from 'react'

const Accordion1 = () => (
    <p>
        O Programa "Partner" da ZIYØN representa mais do que uma simples oportunidade de negócio e sim uma jornada exclusiva rumo ao sucesso financeiro. Ao se afiliar à plataforma
        como revendedor(a), você terá acesso privilegiado para comercializar o produto em questão. Com uma abordagem simplificada e eficiente, o Programa "Partner" oferece uma maneira
        única de ganhar dinheiro através da revenda do aplicativo sem sequer sair de casa.<br /><br /><span className='bold'>Mudar de Vida</span><br />
        Imagine ter a liberdade de trabalhar de onde quiser, no seu próprio horário, e ainda assim alcançar o sucesso financeiro que sempre sonhou. Com o Programa "Partner" da ZIYØN,
        essa visão se torna realidade. Não apenas você terá a oportunidade de ganhar dinheiro extra de forma conveniente e flexível, mas também poderá criar um futuro financeiro sólido e
        promissor para si e para sua família. Não deixe essa chance passar; junte-se a nós hoje e embarque nessa jornada emocionante rumo ao sucesso!
        <br /><br /><span className='bold'>Como se cadastrar</span><br />Para fazer parte do programa "Partner" da ZIYØN, Você precisa:<br /><br />
        1º Adquirir o aplicativo na loja da Google Play ou na App Store.<br /><br />
        2º Em seguida, baixá-lo para o seu dispositivo.<br /><br />
        3º Ao fazer o cadastro no aplicativo, é essencial informar o código de indicação <span className='bold'>"ziyon-partner"</span>.<br /><br />
        4º Após esse procedimento, basta aguardar ser contatado via e-mail ou telefone, conforme as informações fornecidas durante o cadastro. No entanto, caso não deseje esperar pelo contato,
        o interessado pode entrar em contato conosco pelo WhatsApp <a href='https://wa.me/5511937223060' target="_blank" rel="noreferrer">{'+55 11 93722 3060'}</a> e escrever "Programa Partner" para receber atendimento e iniciar sua jornada
        rumo ao sucesso financeiro. Estamos aqui para ajudar e garantir que você aproveite ao máximo essa oportunidade incrível!
        <br /><br />
        Status: <span className='bold'>DISPONÍVEL</span><br /><br />
        Por favor, esteja ciente de que o programa é restrito e tem um <span className='bold'>número limitado de vagas disponíveis</span>. Isso significa que apenas um número específico de participantes será aceito. Aproveite a oportunidade exclusiva de fazer parte do nosso programa.

    </p>
)

const Accordion2 = () => (
    <p>
        O valor da comissão aumenta de acordo com o aumento do valor do aplicativo, garantindo que nossos parceiros sejam recompensados justamente pelo seu envolvimento e contribuição. Para começar, oferecemos uma comissão de <span className='bold'>R$40,00</span> por cada indicação bem-sucedida que resulte em uma compra.
        <br /><br />
        ⚠️ É importante destacar que os pagamentos são feitos automaticamente assim que o saldo atinge <span className='bold'>R$100,00</span>. Isso significa que você não precisa solicitar seus ganhos, pois eles são depositados automaticamente e sem complicações. Queremos garantir que nossos parceiros sejam recompensados pelo seu trabalho de forma rápida e conveniente.
        <br /><br />
        No entanto, é importante notar que os valores ficam <span className='bold'>pendentes por um período de 7 dias</span>, durante o qual o cliente tem a oportunidade de solicitar um reembolso. Após esse prazo, o valor é considerado elegível para ser contabilizado em sua carteira e está pronto para ser sacado. <span className='bold'>O valor estando elegível e igual ou maior que R$100,00, a ZIYØN tem um prazo máximo de 7 dias para realizar o depósito. </span>
        Em resumo, nosso programa Partner é uma maneira incrível de transformar seu envolvimento e influência em uma oportunidade financeira significativa. Valorizamos profundamente nossos parceiros e estamos comprometidos em garantir que você seja recompensado de maneira justa e oportuna. Junte-se a nós hoje e comece a colher os benefícios de nossa parceria!
        <br /><br />
        ⚠️ É obrigatório que a conta para transferência esteja registrada no mesmo nome e CPF ou ID do titular do aplicativo, podendo ser necessário um documento para a verificação.
    </p>
)

const Accordion3 = () => (
    <p>
        Oferecemos uma variedade de recursos de marketing, incluindo <span className='bold'>vídeos, imagens, criativos e palavras-chave</span>, todos disponíveis abaixo. Esses materiais estão prontos para ajudar nossos parceiros a promover nosso produto ou serviço de forma eficaz.
        <br /><br />
    </p>
)

const Accordion4 = () => (
    <p>
        <span className='bold'><h2>Produtividade:</h2></span>
        <ol>
            <li>produtividade</li>
            <li>como ser mais produtivo</li>
            <li>como ser mais produtivo no dia a dia</li>
            <li>como aumentar a produtividade</li>
            <li>como ser mais produtiva</li>
            <li>como parar de procrastinar e ser produtivo na prática</li>
            <li>domine suas finanças</li>
            <li>dominio financeiro</li>
            <li>como juntar 10 mil</li>
            <li>como juntar 10 mil em 1 ano tabela</li>
            <li>como juntar 20 mil em 1 ano</li>
            <li>planner</li>
            <li>planner de estudos</li>
            <li>planner digital</li>
            <li>planilha de gastos</li>
            <li>planilha de gastos mensais</li>
            <li>planilha de gastos pessoais</li>
            <li>aplicativo de organização</li>
            <li>aplicativo de rotina</li>
            <li>aplicativo de tarefa</li>
            <li>aplicativo para organizar rotina</li>
            <li>aplicativo para organizar tarefas diárias</li>
            <li>aplicativos de produtividade</li>
            <li>app de rotina diaria</li>
            <li>app para organizar tarefas</li>
            <li>app tarefas</li>
            <li>criar rotina</li>
            <li>lista de rotina diária</li>
            <li>organizador de ideias</li>
            <li>organizador de rotina</li>
            <li>organizador de tarefas</li>
            <li>organize</li>
            <li>planner gratuito</li>
            <li>rotina organizada</li>
        </ol>

        <span className='bold'><h2>Finanças:</h2></span>
        <ol>
            <li>como organizar as finanças</li>
            <li>como organizar as finanças pessoais</li>
            <li>como organizar as contas</li>
            <li>como organizar finanças pessoais</li>
            <li>como organizar gastos</li>
            <li>como organizar minhas contas</li>
            <li>como organizar minhas finanças</li>
            <li>controle de gastos</li>
            <li>estudo sobre finanças</li>
            <li>organizar finanças</li>
            <li>finanças</li>
            <li>investimento</li>
            <li>calculadora</li>
            <li>calculadora de juros</li>
            <li>calculadora de investimento</li>
            <li>lista de compras</li>
            <li>orçamentos</li>
        </ol>

        <span className='bold'><h2>Organização:</h2></span>
        <ol>
            <li>como organizar a casa durante a semana</li>
            <li>como organizar a rotina</li>
            <li>como organizar as tarefas de casa</li>
            <li>como organizar as tarefas diarias</li>
            <li>como organizar as tarefas do trabalho</li>
            <li>como organizar minha rotina</li>
            <li>como organizar minha vida</li>
            <li>como organizar o tempo</li>
            <li>como organizar vida</li>
            <li>como organizar a vida</li>
            <li>como fazer planejamento semanal</li>
            <li>como fazer uma rotina</li>
            <li>como fazer uma rotina do dia a dia</li>
            <li>como montar uma rotina</li>
            <li>criar rotina</li>
            <li>organize</li>
            <li>organizar estudos</li>
            <li>organizar ideias</li>
            <li>organizar o dia</li>
            <li>organizar rotina</li>
        </ol>

        <span className='bold'><h2>Tarefas:</h2></span>
        <ol>
            <li>agenda</li>
            <li>agenda pessoal</li>
            <li>app de rotina diaria</li>
            <li>aplicativo de cronograma de estudos</li>
            <li>aplicativo de tarefa</li>
            <li>aplicativo de rotina</li>
            <li>lista de rotina diária</li>
            <li>tarefas</li>
        </ol>
    </p>
)

const Accordion5 = () => (
    <p>
        Após adquirir o app ZIYØN e se cadastrar em nossa plataforma, você terá acesso exclusivo ao Portal Partner, onde poderá gerenciar sua participação em nosso programa.<br /><br />
        Para acessar o portal, basta informar seu e-mail e senha. ⚠️ É importante lembrar que durante o processo de cadastro do aplicativo, após o download da loja, você será solicitado a inserir um código de indicação. Neste momento, por favor, utilize o código <span className='bold'>"ziyon-partner"</span> para garantir seu cadastro em nosso programa. Tenha em mente que o programa é restrito e possui um número limitado de vagas disponíveis.<br /><br />
        Após o cadastramento, vamos gerar uma senha exclusiva para você, que lhe concederá acesso ao portal. Este painel fornecerá informações sobre suas bonificações, quantidades a receber, seu código de indicação, recibos de saque, bem como dados bancários e pessoais para transferências. É importante ressaltar que o painel é de uso informativo e certifique-se de guardar sua senha de forma segura e não a compartilhe com ninguém para evitar possíveis problemas.<br /><br />
        Estamos ansiosos para que você faça parte de nossa comunidade de parceiros de sucesso!
    </p>
)

const Accordion6 = () => (
    <p>
    Ao baixar o aplicativo e fazer parte do programa, eu me comprometo a utilizar os arquivos fornecidos exclusivamente para fins relacionados à promoção e divulgação dos produtos ou serviços da ZIYØN.
    <br /><br />
    Concordo em não utilizar os arquivos de forma indevida, incluindo, mas não se limitando a: reprodução não autorizada, venda ou distribuição dos arquivos a terceiros sem autorização da ZIYØN.
    <br /><br />
    Comprometo-me a não me passar por outra pessoa ou entidade para obter acesso aos arquivos disponibilizados pela ZIYØN, bem como a não realizar qualquer tipo de atividade fraudulenta ou golpe envolvendo os mesmos.
    <br /><br />
    Reconheço que o descumprimento desses termos poderá resultar na suspensão do aplicativo, da conta e do acesso aos arquivos, bem como em medidas legais cabíveis conforme previsto em lei.
    <br /><br />
    Aceito que a ZIYØN poderá realizar a verificação dos dados fornecidos por mim, incluindo a verificação da conta bancária para transferências, a fim de garantir o cumprimento dos termos estabelecidos neste documento.
</p>
)

export { Accordion1, Accordion2, Accordion3, Accordion4, Accordion5, Accordion6 }