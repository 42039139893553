import React from 'react'
import Navbar from '../../../components/Navigation'

import { Accordion1, Accordion2, Accordion3, Accordion4, Accordion5, Accordion6 } from './strings/Portuguese'
import folder from '../../../../../assets/svg/folder.svg'

import './../../../components/style/accordion.css'
import '../../../../../structure/css/anim.css'
import './style.css'

function Partner() {
    const selectedPage = "partner"
    const openAccordion = (id) => {
        const accordion = document.getElementById(id)
        const panel = document.getElementById(`ind-${id}`)

        if (accordion.classList.contains('off')) {
            accordion.classList.remove('off')
            panel.textContent = '-'
            accordion.classList.add('fade-in')
        } else {
            accordion.classList.add('off')
            panel.textContent = '+'
        }
    }

    return (
        <main className='page-benefits'>
            <Navbar selectedPage={selectedPage} />
            <section className='content-benefits'>
                <h1>Partner</h1>
                <div onClick={() => openAccordion('id-1')} class="accordion">
                    <div><p>O que é ziyøn partner?</p></div>
                    <div><p id='ind-id-1' className='indicative'>-</p></div>
                </div>
                <div id='id-1' class="panel">
                    <Accordion1 />
                </div>

                <div onClick={() => openAccordion('id-2')} class="accordion">
                    <div><p>Valores e Recebimento</p></div>
                    <div><p id='ind-id-2' className='indicative'>+</p></div>
                </div>
                <div id='id-2' class="panel off">
                    <Accordion2 />
                </div>

                <div onClick={() => openAccordion('id-3')} class="accordion">
                    <div><p>Material de Trabalho</p></div>
                    <div><p id='ind-id-3' className='indicative'>+</p></div>
                </div>
                <div id='id-3' class="panel off">
                    <Accordion3 />
                    <div className='container-folder'>
                        <div className='folder'>
                            <img alt="folder" src= {folder} />
                            <p>reels</p>
                        </div>
                        <div className='folder'>
                        <img alt="folder" src= {folder} />
                            <p>post</p>
                        </div>
                        <div className='folder'>
                        <img alt="folder" src= {folder} />
                            <p>mockups</p>
                        </div>
                    </div>
                </div>

                <div onClick={() => openAccordion('id-4')} class="accordion">
                    <div><p>Motor de busca</p></div>
                    <div><p id='ind-id-4' className='indicative'>+</p></div>
                </div>
                <div id='id-4' class="panel off">
                    <Accordion4 />
                </div>

                <div onClick={() => openAccordion('id-5')} class="accordion">
                    <div><p>Aplicativo Partner</p></div>
                    <div><p id='ind-id-5' className='indicative'>+</p></div>
                </div>
                <div id='id-5' class="panel off">
                    <Accordion5 />
                </div>

                <div onClick={() => openAccordion('id-6')} class="accordion">
                    <div><p>Compromisso e Uso de Arquivos</p></div>
                    <div><p id='ind-id-6' className='indicative'>+</p></div>
                </div>
                <div id='id-6' class="panel off">
                    <Accordion6 />
                </div>
            </section>
        </main>
    )
}

export default Partner