import React from 'react'
import Navbar from './../../../components/Navigation'

import { Accordion1, Accordion2, Accordion3, Accordion4, Accordion5, Accordion6, Accordion7, Accordion8, Accordion9, Accordion10, Accordion11 } from './strings/Portuguese'

import './../../../components/style/accordion.css'
import '../../../../../structure/css/anim.css'

function Questions() {
    const selectedPage = "questions"

    const openAccordion = (id) => {
        const accordion = document.getElementById(id)
        const panel = document.getElementById(`ind-${id}`)

        if (accordion.classList.contains('off')) {
            accordion.classList.remove('off')
            panel.textContent = '-'
            accordion.classList.add('fade-in')
        } else {
            accordion.classList.add('off')
            panel.textContent = '+'
        }
    }

    return (
        <main className='page-benefits'>
            <Navbar selectedPage={selectedPage} />
            <section className='content-benefits'>
                <h1>FAQ - Perguntas frequentes</h1>
                <div onClick={() => openAccordion('id-1')} class="accordion">
                    <div><p>O que é a ziyøn?</p></div>
                    <div><p id='ind-id-1' className='indicative'>-</p></div>
                </div>
                <div id='id-1' class="panel">
                    <Accordion1 />
                </div>

                <div onClick={() => openAccordion('id-2')} class="accordion">
                     <div><p>Qual a finalidade do app?</p></div>
                    <div><p id='ind-id-2' className='indicative'>+</p></div>
                </div>
                <div id='id-2' class="panel off">
                    <Accordion2 />
                </div>

                <div onClick={() => openAccordion('id-3')} class="accordion">
                    <div><p>Como acesso as ferramentas?</p></div>
                    <div><p id='ind-id-3' className='indicative'>+</p></div>
                </div>
                <div id='id-3' class="panel off">
                    <Accordion3 />
                </div>

                <div onClick={() => openAccordion('id-4')} class="accordion">
                    <div><p>A plataforma é paga?</p></div>
                    <div><p id='ind-id-4' className='indicative'>+</p></div>
                </div>
                <div id='id-4' class="panel off">
                    <Accordion4 />
                </div>

                <div onClick={() => openAccordion('id-5')} class="accordion">
                    <div><p>Vitalício! Como funciona?  </p></div>
                    <div><p id='ind-id-5' className='indicative'>+</p></div>
                </div>
                <div id='id-5' class="panel off">
                    <Accordion5 />
                </div>

                <div onClick={() => openAccordion('id-6')} class="accordion">
                    <div><p>Preciso pagar por alguma coisa mais?</p></div>
                    <div><p id='ind-id-6' className='indicative'>+</p></div>
                </div>
                <div id='id-6' class="panel off">
                    <Accordion6 />
                </div>

                <div onClick={() => openAccordion('id-7')} class="accordion">
                    <div><p>Com que frequências são lançadas novas versões?</p></div>
                    <div><p id='ind-id-7' className='indicative'>+</p></div>
                </div>
                <div id='id-7' class="panel off">
                    <Accordion7 />
                </div>

                <div onClick={() => openAccordion('id-8')} class="accordion">
                    <div><p>O valor da plataforma será sempre o mesmo?</p></div>
                    <div><p id='ind-id-8' className='indicative'>+</p></div>
                </div>
                <div id='id-8' class="panel off">
                    <Accordion8 />
                </div>

                <div onClick={() => openAccordion('id-9')} class="accordion">
                    <div><p>Meus dados são compartilhados?</p></div>
                    <div><p id='ind-id-9' className='indicative'>+</p></div>
                </div>
                <div id='id-9' class="panel off">
                    <Accordion9 />
                </div>

                <div onClick={() => openAccordion('id-10')} class="accordion">
                    <div><p>Quem pode usar a plataforma?</p></div>
                    <div><p id='ind-id-10' className='indicative'>+</p></div>
                </div>
                <div id='id-10' class="panel off">
                    <Accordion10 />
                </div>

                <div onClick={() => openAccordion('id-11')} class="accordion">
                    <div><p>Não encontrou sua duvida?</p></div>
                    <div><p id='ind-id-11' className='indicative'>+</p></div>
                </div>
                <div id='id-11' class="panel off">
                    <Accordion11 />
                </div>
            </section>
        </main>
    )
}

export default Questions