import home from '../assets/img/home.png'
import homeSmall from '../assets/img/homeSmall.png'
import taskTools from '../assets/img/task.png'
import financeTools from '../assets/img/finance.png'
import budgetTools from '../assets/img/budget.png'
import calculatorTools from '../assets/img/calculator.png'
import taskSmallTools from '../assets/img/taskMobile.png'
import financeSmallTools from '../assets/img/financeMobile.png'
import budgetSmallTools from '../assets/img/budgetMobile.png'
import calculatorSmallTools from '../assets/img/calculatorMobile.png'
import coinTools from '../assets/img/coin.png'
import coinSmallTools from '../assets/img/coinMobile.png'
import learnTools from '../assets/img/learn.png'
import learnSmallTools from '../assets/img/learnMobile.png'
import partnerTools from '../assets/img/partiner.png'
import partnerSmallTools from '../assets/img/partnerMobile.png'
import lawTools from '../assets/img/law.png'
import lawSmallTools from '../assets/img/lawMobile.png'

export {
  home,
  homeSmall,
  taskTools,
  financeTools,
  budgetTools,
  calculatorTools,
  taskSmallTools,
  financeSmallTools,
  budgetSmallTools,
  calculatorSmallTools,
  coinTools,
  coinSmallTools,
  learnTools,
  learnSmallTools,
  partnerTools,
  partnerSmallTools,
  lawTools,
  lawSmallTools
}
