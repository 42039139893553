const frStrings = {
    fr: 'Français',
    en: 'Anglais',
    pt: 'Portugais',
    appleText: 'AppStore',
    googleText: 'GooglePlay',
    availableApple: 'Disponible à',
    availableGoogle: 'Téléchargez sur',
    language: 'Langue du site Web',
    initials: 'FR',
    tools: 'Outils',
    coin: 'iØn Coin',
    doc: 'Documentation',
    price: 'Valeur',
    headerSubTitle: 'Bien plus qu\'une application, un nouveau concept.',
    headerTitle: 'Une expérience unique à',
    headerTitleAccent: 'gérer votre vie.',
    headerDesc: 'Gagnez du temps en utilisant notre application et faites partie de ce nouveau concept. Retrouvez-le sur les plateformes GooglePlay et AppStore.',
    onePeople: 'Une personne',
    towPeople: 'Deux personnes',
    hundred: '100 mille téléchargements',
    fiveHundred: '500 mille téléchargements',
    million: '1 million de téléchargements',
    hundredTitleBold: 'R$50 MILLE',
    fiveHundredTitleBold: 'R$100 MILLE',
    millionTitleBold: 'R$1 MILLION',
    hundredTitle: 'DANS VOS FINANCES',
    fiveHundredTitle: 'DANS VOTRE ENTREPRISE',
    millionTitle: 'DANS VOS FINANCES',
    hundredDesc: 'Pour chaque tranche de cent mille ventes, nous investirons cinquante mille R$ dans vos finances personnelles.',
    fiveHundredDesc: 'Pour chaque tranche de cinq cent mille ventes, nous investirons 100 000 R$ dans votre entreprise ou dans toute idée.',
    millionDesc: 'Pour chaque million de ventes, nous investirons 1 million de R$ dans vos finances personnelles, vous augmenterez ainsi vos investissements.',
    benefitsTerms: 'Voir les termes et conditions',
    priceTitle: 'Évolution de la plateforme',
    priceSubtitle: 'Rejoignez-nous aujourd\'hui!',
    priceTime: 'Jusqu\'à la prochaine version',
    priceModel: 'Modèle',
    priceLifetime: '/utilisation à vie',
    priceDesc: 'L\'utilisation est à vie et vous recevrez toutes les mises à jour au fil du temps',
    priceNext: 'Version suivante',
    priceDev: 'En développement',
    priceSoon: 'Arrive bientôt',
    priceOld: 'Versions précédentes',
    toolTask: 'Organisez vos tâches',
    toolFinance: 'Mes finances',
    toolBudget: 'Budgets et achats',
    toolCalculator: 'Calculateur d\'intérêts',
    toolLaw: 'Monde juridique',
    toolAffiliate: 'Programme d\'affiliation',
    toolCloud: 'Données dans les nuages',
    toolIA: 'Intelligence artificielle',
    toolPodcast: 'Podcasts et histoires',
    toolLearn: 'Cours et formations',
    toolMedia: 'Communauté numérique',
    toolShop: 'Boutique et produits',
    toolCoin: 'Monnaie et crypto',
    toolGraphic: 'Graphiques des crypto',
    toolBuy: 'Acheter (IØN) Coin',
    toolBank: 'Banque numérique',
    toolInfest: 'Investissements',
    toolNomad: 'Nomad',
    toDoTitle: 'Principaux outils',
    toDoSubtitle: 'Qu\'est-ce que tu aimerais faire?',
    toDoTask: 'Organiser les tâches',
    toDoFinance: 'Mes finances',
    toDoBudget: 'Budgets achats',
    toDoCalculator: 'Calculateur d\'intérêts',
    coinTitle: 'ZIYØN COIN',
    coinSubtitle: 'Une monnaie qui redéfinit le concept de monnaie et ouvre les portes d\'un monde aux possibilités infinies. Avec son concept global, il surmonte les barrières et émerge pour changer le cours de l\'histoire.',
    coinTransfer: 'Transactions rapides',
    coinTransferDesc: 'Rapidité et efficacité des transactions, permettant des transferts instantanés dans le monde entier, sans intermédiaires.',
    coinSociety: 'Communauté engagée',
    coinSocietyDesc: 'Excellentes opportunités de réseautage et d\'apprentissage avec des personnes partageant les mêmes intérêts.',
    coinGraphic: 'Potentiel d\'appréciation',
    coinGraphicDesc: 'Déflationniste et exclusif, limité à 100M, adossé à de l\'or physique. Améliorez votre puissance avec la solidité du métal.',
    affilierLeftOne: 'En direct de notre plateforme! Que',
    affilierLeftTwo: 'c\'est possible',
    affilierLeftThree: 'avec notre programme ',
    affilierLeftFour: 'd\'affiliation',
    affilierDesc: 'Avec chaque objectif une augmentation de valeur, meilleure est la commission',
    affilierRightOne: 'Embarquement et effet de levier',
    affilierRightTwo: 'vos atouts',
    affilierRightThree: 'via le programme d\'affiliation',
    descBrand: 'Une excellente solution pour gérer votre vie, au-delà d\'une application, d\'un style de vie. Ici, nous avons la gestion financière, des notes et des rappels, des listes de courses, des budgets, des calculateurs d\'intérêts, des cotations de devises, des cours et des conseils juridiques, et ce n\'est que le début. Vivez dans cet écosystème ou à partir de celui-ci avec notre programme partenaire.',
    policies: 'Politiques',
    policiesOne: '100 mille ventes',
    policiesTwo: '500 mille ventes',
    policiesThree: '1 million de ventes',
    policiesFour: 'Conditions d\'utilisation',
    policiesFive: 'Remboursement',
    contact: 'Contacts'
};

export default frStrings;