import React from 'react'

const Accordion1 = () => (
    <p>
        Prezado Cliente,<br /><br />
        Agradecemos por escolher nossos produtos ou serviços! Nosso compromisso é proporcionar uma experiência satisfatória em todas as compras realizadas em nossas plataformas.
        <br /><br />
        <span className='bold'>1. Reembolso e Cancelamento:</span><br /><br />
        Você tem o direito de solicitar o reembolso ou cancelamento de sua compra dentro dos 7 dias após a compra. Este direito está em conformidade com as diretrizes estabelecidas pelo Código de Defesa do Consumidor (CDC) do Brasil.
        <br /><br />
        <span className='bold'>2. Procedimento para Reembolso e Cancelamento:</span><br /><br />
        - Se a compra foi realizada através da App Store (iOS), por favor, siga os procedimentos fornecidos pela Apple para solicitar o reembolso ou cancelamento.<br /><br />
        - Se a compra foi realizada através da Google Play (Android), utilize os métodos disponibilizados pelo Google para requisitar o reembolso ou cancelamento.<br /><br />
        <span className='bold'>3. Suporte ao Cliente:</span><br /><br />
        Para qualquer problema ou dúvida relacionada ao processo de reembolso ou cancelamento, entre em contato com nosso suporte ao cliente. Estamos aqui para ajudar a resolver quaisquer questões que possam surgir durante esse processo.<br /><br />
        <span className='bold'>4. Contato com o Suporte:</span><br /><br />
        <span className='oblique'>WhatsApp:</span> <br />
        - Financeiro e Suporte BR: +55 11 9 3722 3060  <br />
        - Financeiro e Suporte FR: Em breve <br />
        <br /> <br />
        <span className='oblique'>E-mail:</span> <br />
        - Canal de suporte: <a href='mailto:support@ziyon.fr' target="_blank" rel="noreferrer">{'support@ziyon.fr'}</a><br />
        - Canal financeiro: <a href='mailto:contact@ziyon.fr' target="_blank" rel="noreferrer">{'contact@ziyon.fr'}</a><br /><br />
        <span className='bold'>5. Prazo para Processamento:</span><br /><br />
        O reembolso será processado dentro de do prazo, estabelecido pela loja.
        Lembramos que esta política está em conformidade com as leis e regulamentações brasileiras. Estamos comprometidos em garantir a satisfação do cliente e em proporcionar uma experiência transparente e justa em todas as interações.
        Agradecemos pela compreensão e estamos à disposição para qualquer esclarecimento adicional.<br /><br />
        Atenciosamente,<br /><br />
        ZIYØN
    </p>
)

export { Accordion1 }