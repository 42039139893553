import React from "react";

const Accordion1 = () => (
  <p>
    Estes Termos de Uso de Dados (“Termos”) regem o uso dos seus dados pessoais
    pela ZIYØN, uma empresa estabelecida na França, com endereço registrado em
    60 rue François 1er - Paris e também no Brasil em Av. Paulista, nº 1636 -
    Sala 1504, Bela Vista, São Paulo - CEP - 01310-200.
  </p>
);

const Accordion2 = () => (
  <p>
    Ao fornecer seus dados pessoais à ZIYØN, você concorda com a coleta, uso,
    armazenamento e compartilhamento dos seus dados pessoais, conforme descrito
    nestes Termos. Caso não concorde com estes Termos, por favor, desative a
    função dentro do aplicativo nas configurações do app.{" "}
  </p>
);

const Accordion3 = () => (
  <div>
    <p>
      A ZIYØN coleta os seguintes dados pessoais:
      <br />
      <br />● Nome completo
      <br />● Endereço de e-mail
      <br />● Número de telefone
    </p>
  </div>
);

const Accordion4 = () => (
  <p>
    A ZIYØN coleta e utiliza seus dados pessoais com as seguintes finalidades:
    <br />
    <br />● Fornecer os serviços solicitados por você;
    <br />● Manter você informado sobre atualizações, promoções e novidades
    relacionadas aos nossos produtos e serviços;
    <br />● Melhorar nossos produtos e serviços;
    <br />● Realizar análises e pesquisas de mercado;
    <br />● Cumprir obrigações legais ou regulatórias.
  </p>
);

const Accordion5 = () => (
  <p>
    A ZIYØN poderá compartilhar seus dados pessoais com terceiros, incluindo
    nossos parceiros, apenas quando necessário para as finalidades descritas na
    quando necessário para as finalidades descritas na seção 3 destes Termos.
    Esses terceiros estão obrigados a manter a confidencialidade e segurança dos
    seus dados pessoais e utilizá-los apenas de acordo com nossas instruções.{" "}
  </p>
);

const Accordion6 = () => (
  <p>
    A ZIYØN adota medidas técnicas e organizacionais apropriadas para proteger
    seus dados pessoais contra acesso não autorizado, divulgação, perda,
    alteração ou destruição. No entanto, é importante ressaltar que nenhuma
    transmissão de dados pela internet ou armazenamento eletrônico é
    completamente segura, e não podemos garantir a segurança absoluta dos seus
    pessoais.
  </p>
);

const Accordion7 = () => (
  <p>
    Você tem o direito de solicitar acesso, correção, exclusão, restrição ou
    portabilidade dos seus dados pessoais, de acordo com as disposições da LGPD.
    Para exercer esses direitos ou caso tenha qualquer dúvida sobre o uso dos
    seus dados pessoais, entre em contato conosco utilizando os detalhes
    fornecidos na seção 7 destes Termos.
  </p>
);

const Accordion8 = () => (
  <p>
    Se você tiver alguma dúvida ou preocupação sobre estes Termos ou sobre o uso
    dos seus dados pessoais pela ZIYØN, entre em contato conosco.
    contact@ziyon.fr
  </p>
);

const Accordion9 = () => (
  <p>
    A ZIYØN reserva-se o direito de atualizar estes Termos de Uso de Dados
    periodicamente. Recomendamos que você reveja ele para que você fique ciente
    de todas as condições e alterações.
  </p>
);

const Accordion10 = () => (
  <p>
    Nossos clientes têm total controle sobre seus dados e contas. Através do nosso aplicativo, eles podem facilmente apagar sua conta e todos os dados associados a ela com apenas alguns cliques. Além disso, oferecemos a opção de solicitar a exclusão de conta e dados via e-mail, garantindo uma experiência conveniente e segura para nossos clientes.
    <br />
    <br />1 - Abra a tela de configurações e procure pelo botão 'apagar conta';
    <br />2 - Clique no botão e confirme;
    <br />
    <br />
    Entre em contato conosco e solicite através dos seguintes contatos:<br /><br />
    - WhatsApp: <a href='https://wa.me/5511937223060' target="_blank" rel="noreferrer">{'+55 11 93722 3060'}</a><br/>
    - E-mail: <a href='mailto:contact@ziyon.fr' target="_blank" rel="noreferrer">{'contact@ziyon.fr'}</a><br/>
  </p>
);

export {
  Accordion1,
  Accordion2,
  Accordion3,
  Accordion4,
  Accordion5,
  Accordion6,
  Accordion7,
  Accordion8,
  Accordion9,
  Accordion10,
};
