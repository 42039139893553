import React from 'react'

const Accordion1 = () => (
    <p>
        Estamos empolgados por você escolher nossos produtos e serviços. Antes de começarmos, gostaríamos de informar que nossos produtos e serviços são exclusivamente oferecidos aos titulares de contas que acessam nossa plataforma por meio do nosso aplicativo oficial. Queremos garantir a melhor experiência possível aos nossos usuários, e é por isso que limitamos o acesso dessa maneira. Você terá acesso a nossos produtos e serviços unicamente através de nossa plataforma. 
        <br /><br />Olhamos constantemente para as preferências de nosso clientes, e é necessário ajustar e melhorar nossos produtos e serviços de tempos em tempos. Isso significa que podemos realizar mudanças, atualizações ou até mesmo descontinuar certos recursos. No entanto, queremos assegurar a você que, mesmo quando fizermos alterações, nossa prioridade é minimizar qualquer impacto negativo para nossos clientes.
        <br /><br />A ZIYØN está comprometida em continuar aprimorando seus produtos e serviços para atender às suas necessidades. Isso significa que lançaremos novas versões e atualizações regularmente. No entanto, não seguimos uma ordem estrita de lançamento. Estamos sempre atentos às necessidades dos nossos clientes e à evolução do mercado, e nossas decisões de lançamento são baseadas nisso. Portanto nosso escopo de lançamento pode ser alterado.
        <br /><br />Afirmamos nossa total dedicação em oferecer aos nossos clientes produtos e serviços de alta qualidade. Estamos comprometidos em fornecer suporte e assistência excepcionais sempre que for preciso. Valorizamos sua confiança e trabalhamos diligentemente para manter assim.
    </p>
)

const Accordion2 = () => (
    <p>
        Na ZIYØN, estamos comprometidos em oferecer uma ampla gama de produtos e serviços para atender às suas necessidades. Nossos recursos são projetados para enriquecer sua experiência e facilitar sua vida:
        <br /><br /><span className='bold'>Comunidade Digital:</span> Desfrute de uma plataforma interativa com Stories, chats e posts para se conectar com outros membros da nossa comunidade e compartilhar conhecimento.
        <br /><br /><span className='bold'>Inteligência Artificial:</span> Nossa IA está aqui para ajudar a responder suas dúvidas e oferecer suporte personalizado para tornar sua jornada mais tranquila.
        <br /><br /><span className='bold'>Programa de Afiliados:</span> Participe do nosso programa de afiliados, onde cada indicação bem-sucedida garante comissões para você. Uma maneira de recompensarmos sua confiança e apoio.
        <br /><br /><span className='bold'>Agenda de Tarefas e Reuniões:</span> Mantenha seu dia organizado com nossa agenda de tarefas e reuniões, garantindo que você tenha tudo sempre em ordem.
        <br /><br /><span className='bold'>Gestão Financeira:</span> Tenha controle total sobre suas finanças pessoais com nossa ferramenta, auxiliando você a atingir seus objetivos financeiros.
        <br /><br /><span className='bold'>Orçamentos e Compras:</span> Compare preços e produtos em várias lojas para tomar decisões de compra bem como economizar tempo e dinheiro.
        <br /><br /><span className='bold'>Calculadora de Juros:</span> Facilite cálculos de financiamentos e investimentos, permitindo que você tome decisões financeiras mais inteligentes.
        <br /><br /><span className='bold'>Mundo Jurídico:</span> Acesse conhecimentos básicos sobre seus direitos em produtos, serviços e tributos, para melhorar sua compreensão legal.
        <br /><br /><span className='bold'>Cursos:</span> Aprenda sobre gestão, finanças, investimentos e muito mais através do nosso app.
        <br /><br /><span className='bold'>Banco Digital Intercontinental:</span> Acesse sua conta e gerencie seu dinheiro de qualquer lugar onde nosso aplicativo estiver disponível.
        <br /><br /><span className='bold'>Loja Digital:</span> Explore nossa seleção exclusiva de produtos e serviços disponíveis apenas para utilizadores da nossa plataforma.
        <br /><br /><span className='bold'>Moeda Digital:</span> Uma moeda digital com lastro em ouro e deflacionária, oferecendo opções de investimento seguras e transações eficientes.
        <br /><br /><span className='bold'>Podcast:</span> Fique atualizado com as últimas novidades em economia, mercado e muito mais por meio de nosso podcast informativo.
        <br /><br /><span className='bold'>Cartão de Crédito:</span> Um cartão de crédito versátil que permite fazer compras em qualquer lugar que desejar.
    </p>
)

const Accordion3 = () => (
    <p>
        Queremos lembrar nossos estimados usuários de que, devido à diversidade de produtos e serviços que oferecemos, nem todos eles estarão disponíveis em todas as versões de nosso aplicativo. Nossa plataforma está em constante evolução, e novos recursos podem ou não ser incluídos em futuras versões, dependendo das necessidades e preferências de nossos clientes, bem como de nossos esforços contínuos para melhorar sua experiência.
        <br /><br />Antes de fazer o download do aplicativo ou tomar qualquer decisão de compra, incentivamos fortemente que você consulte o que estará disponível na versão atual da loja. Essa informação detalhada estará prontamente disponível no "Overview" (Resumo) aqui na área de documentações. Esta prática garantirá que você tenha uma compreensão clara dos recursos e serviços que terá à sua disposição ao usar nosso aplicativo.
        <br /><br />Agradecemos sua compreensão e comprometimento em explorar a ZIYØN de forma informada e aprimorar sua experiência conosco. Estamos sempre trabalhando para atender às suas necessidades e oferecer soluções que agreguem valor à sua vida.
    </p>
)
export { Accordion1, Accordion2, Accordion3 }