import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'

import Home from '../source/Home/Home';
import Document from '../source/Documentation/doc/HomeDocument';
import Passport from '../source/Documentation/doc/sectors/Passport/Passport';
import Terms from '../source/Documentation/doc/sectors/Terms/Terms';
import Privacy from '../source/Documentation/doc/sectors/Privacy/Privacy';
import Payments from '../source/Documentation/doc/sectors/Payments/Payments';
import Products from '../source/Documentation/doc/sectors/Products/Products';
import Purchase from '../source/Documentation/doc/sectors/Purchase/Purchase';
import Benefits from '../source/Documentation/doc/sectors/Benefits/Benefits';
import Support from '../source/Documentation/doc/sectors/Support/Support';
import Questions from '../source/Documentation/doc/sectors/Questions/Questions';
import Overview from '../source/Documentation/doc/sectors/Overview/Overview';
import Partner from '../source/Documentation/doc/sectors/Partner/Partner';

import Hundred from '../source/Documentation/doc/sectors/Benefits/Hundred';
import FiveHundred from '../source/Documentation/doc/sectors/Benefits/Five';
import Million from '../source/Documentation/doc/sectors/Benefits/Million';

import Crypto from '../source/Documentation/doc/sectors/Coin/Crypto';

function AppRouter() {
  return (
    <Router>
      <Routes>
        {/* home route */}
        <Route path="/" element={<Home />} />\
        {/* documentation route */}
        <Route path="/doc" element={<Document/>} />
        <Route path="/doc/passport" element={<Passport/>} />
        <Route path="/doc/terms" element={<Terms/>} />
        <Route path="/doc/privacy" element={<Privacy/>} />
        <Route path="/doc/payments" element={<Payments/>} />
        <Route path="/doc/products" element={<Products/>} />
        <Route path="/doc/purchase" element={<Purchase/>} />
        <Route path="/doc/benefits" element={<Benefits/>} />
        <Route path="/doc/support" element={<Support/>} />
        <Route path="/doc/questions" element={<Questions/>} />
        <Route path="/doc/overview" element={<Overview/>} />
        <Route path="/doc/partner" element={<Partner/>} />
        <Route path="/doc/coin" element={<Crypto/>} />
        {/* benefits route */}
        <Route path="/doc/benefits/hundredthousand" element={<Hundred/>} />
        <Route path="/doc/benefits/fivehundredthousand" element={<FiveHundred/>} />
        <Route path="/doc/benefits/million" element={<Million/>} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
