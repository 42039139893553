const enStrings = {
    fr: 'French',
    en: 'English',
    pt: 'Portuguese',
    appleText: 'AppStore',
    googleText: 'GooglePlay',
    availableApple: 'Available at',
    availableGoogle: 'Download at',
    language: 'Website language',
    initials: 'EN',
    tools: 'Tools',
    coin: 'iØn Coin',
    doc: 'Documentation',
    price: 'Price',
    headerSubTitle: 'Much more than an app, a new concept.',
    headerTitle: 'A unique experience at',
    headerTitleAccent: 'manage your life.',
    headerDesc: 'Save time using our app and be part of this new concept. Find it on GooglePlay and AppStore platforms.',
    onePeople: 'A person',
    towPeople: 'Two people',
    hundred: '100 thousand downloads',
    fiveHundred: '500 thousand downloads',
    million: '1 million downloads',
    hundredTitleBold: 'R$50 THOUSAND',
    fiveHundredTitleBold: 'R$100 THOUSAND',
    millionTitleBold: 'R$1 MILLION',
    hundredTitle: 'IN YOUR FINANCES',
    fiveHundredTitle: 'IN YOUR BUSINESS',
    millionTitle: 'IN YOUR FINANCES',
    hundredDesc: 'For every 100,000 sales, we will invest R$50,000 in your personal finances so you can leverage your investments.',
    fiveHundredDesc: 'For every 500,000 sales, we will invest R$100,000 in your company or in any business idea you want to open.',
    millionDesc: 'For every 1 million in sales, we will invest R$1 million in your personal finances, so you will increase your investments.',
    benefitsTerms: 'See terms and conditions',
    priceTitle: 'Platform evolution',
    priceSubtitle: 'Don\'t waste time join us today!',
    priceTime: 'Until the next version',
    priceModel: 'Model',
    priceLifetime: '/lifetime use',
    priceDesc: 'Where we are vital and you will receive all of these updates at a long time',
    priceNext: 'Next version',
    priceDev: 'Under development',
    priceSoon: 'Coming soon',
    priceOld: 'Previous verses',
    toolTask: 'Organize your tasks',
    toolFinance: 'Orders and purchases',
    toolBudget: 'My finances',
    toolCalculator: 'Jurisdiction Calculator',
    toolLaw: 'Legal world',
    toolAffiliate: 'Affiliate programs',
    toolCloud: 'Data in clouds',
    toolIA: 'Artificial Intelligence',
    toolPodcast: 'Podcast e stories',
    toolLearn: 'Courses and training',
    toolMedia: 'Digital community',
    toolShop: 'Shop and products',
    toolCoin: 'Coin e crypto',
    toolGraphic: 'Crypto Charts',
    toolBuy: 'Buy (IØN) Coin',
    toolBank: 'Digital banking',
    toolInfest: 'Investments',
    toolNomad: 'Nomad',
    toDoTitle: 'Main tools',
    toDoSubtitle: 'What would you like to do?',
    toDoTask: 'Organize tasks',
    toDoFinance: 'Financial management',
    toDoBudget: 'Budgets & purchases',
    toDoCalculator: 'Interest calculator',
    coinTitle: 'ZIYØN COIN',
    coinSubtitle: 'A currency that redefines the concept of money and opens the doors to a world of infinite possibilities. With its global concept, it overcomes barriers and emerges to change the course of history.',
    coinTransfer: 'Fast transactions',
    coinTransferDesc: 'Speed and efficiency in transactions, allowing instant transfers around the world, without intermediaries.',
    coinSociety: 'Engaged community',
    coinSocietyDesc: 'Offering great opportunities for networking, learning and collaborating with people of similar interests.',
    coinGraphic: 'Appreciation potential',
    coinGraphicDesc: 'Deflationary and exclusive, limited to 100M, backed by physical gold. Value your power with the solidity of the safest metal.',
    affilierLeftOne: 'Live from our platform! That',
    affilierLeftTwo: 'it\'s possible',
    affilierLeftThree: 'with our ',
    affilierLeftFour: 'affiliate program',
    affilierDesc: 'With each goal achieved, an increase in value in your pocket, the higher the value, the higher the commission',
    affilierRightOne: 'Embark on our concept and leverage',
    affilierRightTwo: 'your assets',
    affilierRightThree: 'through the affiliate program',
    descBrand: 'A great solution to manage your life, going beyond an app, a lifestyle. Here we have financial management, notes and reminders, shopping lists, budgets, interest calculators, currency quotes, courses and legal guidance, and that\'s just the beginning. Live in this ecosystem or from it with our partner program.',
    policies: 'Policies',
    policiesOne: '100 thousand sales',
    policiesTwo: '500 thousand sales',
    policiesThree: '1 million sales',
    policiesFour: 'Terms of use',
    policiesFive: 'Reimbursement',
    contact: 'Contacts'
};

export default enStrings;