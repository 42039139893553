import profile01 from '../assets/img/profile/joe.png'
import profile02 from '../assets/img/profile/leon.png'
import profile03 from '../assets/img/profile/felipe.png'
import profile04 from '../assets/img/profile/jeff.png'
export {
    profile01,
    profile02,
    profile03,
    profile04
  }
  