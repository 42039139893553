import React from 'react'
import Navbar from '../components/Navigation';
import './homedocument.css'

import { Link } from 'react-router-dom'

//icons
import whatsappIcon from '../../../assets/svg/whatsapp.svg'
import permissionsIcon from '../../../assets/svg/permissions.svg'
import securityIcon from '../../../assets/svg/security.svg'
import exitIcon from '../../../assets/svg/exit.svg'
import homeIcon from '../../../assets/svg/home.svg'
import rightIcon from '../../../assets/svg/arrowdright.svg'

function HomeDocument() {
    const selectedPage = "home";
    return (
        <main className='home-doc'>
            <Navbar selectedPage={selectedPage} />
            <section className='content'>
                <h1><span className='bold'>Bem-vindo</span> a nossa central de documentações </h1>
                <div className='btn-cars'>
                    <Link className='set-link' to="/doc/privacy">
                        <div className='card-item'>
                            <div className='img-card'><img alt="security Icon" src={securityIcon} /></div>
                            <p>Dados</p>
                        </div>
                    </Link>
                    <Link className='set-link' to="/doc/terms">
                        <div className='card-item'>
                            <div className='img-card'><img alt="permissions Icon" src={permissionsIcon} /></div>
                            <p>Políticas</p>
                        </div>
                    </Link>
                    {/* <Link className='set-link' to="/doc/support">
                    </Link> */}
                    <a className='set-link' href='https://wa.me/message/NGUYJALYGJ5VJ1' target="_blank" rel="noreferrer">
                        <div className='card-item'>
                            <div className='img-card'><img alt="discord Icon" src={whatsappIcon} /></div>
                            <p>Suporte</p>
                        </div>
                    </a>
                    <Link className='set-link' to="/">
                        <div className='card-item'>
                            <div className='img-card'><img alt="exit Icon" src={exitIcon} /></div>
                            <p>Sair</p>
                        </div>
                    </Link>
                </div>
            </section>
            <section className='content-mobile'>
                <div className='btn-cars'>
                    <Link className='set-link' to="/doc/privacy">
                        <div className='card-item'>
                            <img className='ajust-img' alt="security Icon" src={securityIcon} />
                            <div className='img-card'>
                                <p>Dados</p>
                                <img alt="discord Icon" src={rightIcon} />
                            </div>
                        </div>
                    </Link>
                    <Link className='set-link' to="/doc/terms">
                        <div className='card-item'>
                            <img className='ajust-img' alt="permissions Icon" src={permissionsIcon} />
                            <div className='img-card'>
                                <p>Políticas</p>
                                <img alt="discord Icon" src={rightIcon} />
                            </div>
                        </div>
                    </Link>
                    <Link className='set-link' to="/doc/support">
                        <div className='card-item'>
                            <img className='ajust-img' alt="discord Icon" src={whatsappIcon} />
                            <div className='img-card'>
                                <p>Suporte</p>
                                <img alt="discord Icon" src={rightIcon} />
                            </div>
                        </div>
                    </Link>
                    <Link className='set-link' to="/">
                        <div className='card-item'>
                            <img className='ajust-img x' alt="exit Icon" src={homeIcon} />
                            <div className='img-card'>
                                <p>Voltar</p>
                                <img alt="discord Icon" src={rightIcon} />
                            </div>
                        </div>
                    </Link>
                </div>
            </section>
        </main>
    )
}

export default HomeDocument