import React from 'react'

const Accordion1 = () => (
    <p>
        Prezado Cliente,<br /><br />
        Gostaríamos de esclarecer que as formas de pagamento disponíveis dentro ou durante a compra de nosso aplicativo estão estritamente vinculadas às condições estabelecidas pelas lojas de aplicativos, App Store e Google Play.
        <br /><br />
        <span className='bold'>1. Processo de Pagamento:</span><br /><br />
        Todos os pagamentos, sejam eles referentes a aquisição do aplicativo, compras dentro do aplicativo (in-app purchases) ou qualquer outra transação financeira, são realizados diretamente através das plataformas de distribuição de aplicativos, ou seja, App Store para usuários iOS e Google Play para usuários Android.
        <br /><br />
        <span className='bold'>2. Não Solicitamos Informações de Pagamento:</span><br /><br />
        Ressaltamos que nossa empresa não solicita informações sensíveis de pagamento, como cartões de crédito, boletos ou qualquer outro método financeiro direto. Todas as transações financeiras são conduzidas exclusivamente pelas lojas de aplicativos, proporcionando um ambiente seguro e confiável para as transações dos usuários.
        <br /><br />
        <span className='bold'>3. Segurança na Transação:</span><br /><br />
        As lojas App Store e Google Play garantem a segurança e a confidencialidade de suas informações financeiras durante o processo de pagamento. Recomendamos que os usuários confiem nas diretrizes e políticas de pagamento dessas lojas para garantir uma experiência segura e protegida.
        <br /><br />
        <span className='bold'>4. Dúvidas ou Problemas:</span><br /><br />
        Em caso de dúvidas, problemas ou questões relacionadas ao processo de pagamento, sugerimos que entre em contato diretamente com o suporte das respectivas lojas de aplicativos. Estamos à disposição para auxiliar no que estiver ao nosso alcance.<br /><br />
        <span className='oblique'>WhatsApp:</span> <br />
        - Financeiro e Suporte BR: +55 11 9 3722 3060  <br />
        - Financeiro e Suporte FR: Em breve <br />
        <br /> <br />
        <span className='oblique'>E-mail:</span> <br />
        - Canal de suporte: <a href='mailto:support@ziyon.fr' target="_blank" rel="noreferrer">{'support@ziyon.fr'}</a><br />
        - Canal financeiro: <a href='mailto:contact@ziyon.fr' target="_blank" rel="noreferrer">{'contact@ziyon.fr'}</a><br /><br />
        Agradecemos pela compreensão e confiança em nossos serviços.<br /><br />
        Atenciosamente,<br /><br />
        ZIYØN
    </p>
)

export { Accordion1 }