import React from 'react'

const Accordion1 = () => (
    <p>Bem-vindo ao nosso aplicativo de Gestão Financeira e Gestão Pessoal. Antes de prosseguir com a utilização deste aplicativo, por favor, leia atentamente os seguintes Termos de Uso, pois eles estabelecem os direitos e obrigações entre você nosso Cliente e a ZIYØN responsável pelo Aplicativo. A ZIYØN SAS - (ziyøn), devidamente inscrito no SIREN sob o n.º 97830392300012 e no comércio n.º FR47978303923 localizada em 60 rue François 1er, Paris, Île-de-France, CEP - 75008.</p>
)

const Accordion2 = () => (
    <p>Ao fazer o download, acessar ou utilizar o Aplicativo, você concorda em cumprir integralmente estes Termos, bem como as leis e regulamentações aplicáveis. Caso não concorde com qualquer parte destes Termos, por favor, não utilize o Aplicativo.</p>
)

const Accordion3 = () => (
    <p>A versão atual do Aplicativo destina-se exclusivamente ao armazenamento de dados financeiros e pessoais no próprio dispositivo do Usuário. Nenhum serviço ou produto adicional está disponível ou acessível através do Aplicativo nesta primeira versão.</p>
)

const Accordion4 = () => (
    <p>
        O Usuário concorda em usar o Aplicativo de forma responsável e legal. Qualquer ação que viole leis, regulamentações ou direitos de terceiros é estritamente proibida. O Usuário é responsável por manter a confidencialidade dos dados armazenados no Aplicativo e por garantir que seu dispositivo esteja seguro e protegido contra acesso não autorizado.
        <br /><br />Além disso, é importante ressaltar que a aquisição deste Aplicativo concede ao Usuário uma licença de uso vitalícia, pessoal e intransferível. Isso significa que cada compra é exclusivamente destinada ao Usuário que a realizou, não permitindo qualquer compartilhamento, clonagem, replicação de telas ou interfaces para fins caluniosos ou enganosos. A Empresa reserva-se o direito de tomar medidas legais em caso de violações desta natureza, sujeitando o infrator a ações judiciais cabíveis.
        <br /><br />É fundamental compreender que, embora a licença de uso seja vitalícia para o Usuário, a propriedade da plataforma permanece exclusivamente com a ZIYØN. Isso inclui todas as interfaces, designs, elementos gráficos e códigos subjacentes. Qualquer tentativa de se passar por um colaborador ou funcionário da ZIYØN, revender a plataforma ou usá-la para atividades fraudulentas bem como realizar manifestações ou indicações falsas, inverídicas ou incorretas sendo de seus administradores, empregados, clientes, parceiros, produtos, serviços ou Aplicativo, marcas, logotipos etc. será considerada uma violação grave destes Termos e sujeitará o infrator às devidas ações legais.
        <br /><br />É de responsabilidade exclusiva do Usuário garantir a segurança e a integridade de seus dados. Em caso de perda de dados, a ZIYØN não será responsável pela recuperação ou restauração dos mesmos. Ademais, é importante destacar que a desinstalação e reinstalação do Aplicativo podem resultar na perda permanente de dados, devendo o Usuário agir com cuidado ao realizar tais ações.
        <br /><br />Ao concordar com estes Termos de Uso e utilizar o Aplicativo, o Usuário está comprometendo-se a aderir a todas as disposições mencionadas acima. Em caso de dúvidas ou preocupações, entre em contato com nosso suporte support@ziyon.fr.
    </p>
)

const Accordion5 = () => (
    <p>A ZIYØN se compromete a zelar pela privacidade e segurança dos dados do Usuário armazenados no Aplicativo, caso tenha alguma informação nas nuvens. Os dados serão armazenados exclusivamente nessa primeira versão no dispositivo do Usuário e não serão compartilhados ou transmitidos para servidores externos. A ZIYØN empregará esforços razoáveis para manter a segurança dos dados, mas não pode garantir a segurança absoluta. O Usuário reconhece e concorda que utiliza o Aplicativo e é responsável pelos riscos. Para mais informações sobre os dados acesse a seção de Privacidade dos dados que é encontrado na área de documentações no site da ZIYØN.  </p>
)

const Accordion6 = () => (
    <p>A ZIYØN reserva-se o direito de modificar, atualizar ou encerrar o Aplicativo a qualquer momento, sem aviso prévio. A Empresa não assume nenhuma obrigação de disponibilizar atualizações ou melhorias específicas do Aplicativo.</p>
)

const Accordion7 = () => (
    <p>O Aplicativo é fornecido "no estado em que se encontra", sem garantias de qualquer tipo, expressas ou implícitas. A ZIYØN não será responsável por quaisquer danos diretos, indiretos, incidentais, consequenciais ou outros, decorrentes ou relacionados ao uso do Aplicativo.</p>
)

const Accordion8 = () => (
    <p>
        Estes Termos são regidos e interpretados de acordo com as leis do Brasil. Qualquer disputa relacionada aos Termos ou ao Aplicativo será submetida à jurisdição exclusiva dos tribunais localizados no Brasil.
        <br /><br />Ao utilizar o Aplicativo, você concorda com estes Termos de Uso. Caso tenha alguma dúvida ou preocupação, entre em contato conosco pelo contact@ziyon.fr.
        <br /><br />Obrigado por escolher o nosso Aplicativo.
        <br /><br />Atenciosamente,
        <br />ZIYØN
    </p>
)

export { Accordion1, Accordion2, Accordion3, Accordion4, Accordion5, Accordion6, Accordion7, Accordion8 }