import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style/navigation.css'

//icons
import menuIcon from '../../../assets/svg/menu.svg'
import closeIcon from '../../../assets/svg/xmark.svg'
import rightIcon from '../../../assets/svg/arrowdright.svg'

function Navigation({ selectedPage }) {
    const [showMenu, setShowMenu] = useState(false)

    const openMenu = () => {
        setShowMenu(true)
    }

    const closeMenu = () => {
        setShowMenu(false)
    }

    return (
        <nav className='navbar-links'>
            <section className='top-nav'>
                <div className='brand'>
                    <div><Link className='brand-link' to="/doc">ZIYØN</Link></div>
                    {!showMenu && (
                        <div className='menu' onClick={openMenu}><img loading="lazy" alt="Menu" src={menuIcon} /></div>
                    )}
                    {showMenu && (
                        <div className='menu' onClick={closeMenu}><img loading="lazy" alt="Close" src={closeIcon} /></div>
                    )}
                </div>
                <div className='desc-brand'><p>Documentações</p></div>
            </section>
            <section className={`links menu-mobile ${showMenu ? 'show' : 'hide'}`}>
                <h4>Termos e políticas</h4>
                <Link
                    className={`link-nav link-text ${selectedPage === 'passport' ? 'active' : ''}`}
                    to="/doc/passport">
                    <div className='link-card'>
                        <p>Passaporte ziyøn</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'terms' ? 'active' : ''}`}
                    to="/doc/terms">
                    <div className='link-card'>
                        <p>Termos de uso do app</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'privacy' ? 'active' : ''}`}
                    to="/doc/privacy">
                    <div className='link-card'>
                        <p>Privacidade dos dados</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'benefits' ? 'active' : ''}`}
                    to="/doc/benefits">
                    <div className='link-card'>
                        <p>Benefícios e metas</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <h4>Serviços e pagamentos</h4>
                <Link
                    className={`link-nav link-text ${selectedPage === 'payments' ? 'active' : ''}`}
                    to="/doc/payments">
                    <div className='link-card'>
                        <p>Formas de pagamentos</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'products' ? 'active' : ''}`}
                    to="/doc/products">
                    <div className='link-card'>
                        <p>Produtos e serviços</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'purchase' ? 'active' : ''}`}
                    to="/doc/purchase">
                    <div className='link-card'>
                        <p>Compras e reembolso</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'partner' ? 'active' : ''}`}
                    to="/doc/partner">
                    <div className='link-card'>
                        <p>Programa Partner</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'coin' ? 'active' : ''}`}
                    to="/doc/coin">
                    <div className='link-card'>
                        <p>iØn crypto</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <h4>Central de ajuda</h4>
                <Link
                    className={`link-nav link-text ${selectedPage === 'support' ? 'active' : ''}`}
                    to="/doc/support">
                    <div className='link-card'>
                        <p>Suporte ao cliente</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'questions' ? 'active' : ''}`}
                    to="/doc/questions">
                    <div className='link-card'>
                        <p>Perguntas e respostas</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
                <Link
                    className={`link-nav link-text ${selectedPage === 'overview' ? 'active' : ''}`}
                    to="/doc/overview">
                    <div className='link-card'>
                        <p>Visão geral do app</p>
                        <img alt="arrow right" src={rightIcon} />
                    </div>
                </Link>
            </section>
        </nav>
    )
}

export default Navigation