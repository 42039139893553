import React from 'react'
import Navbar from './../../../components/Navigation'

import { Accordion1 } from './strings/Portuguese'

import './../../../components/style/accordion.css'
import '../../../../../structure/css/anim.css'

function Payments() {
    const selectedPage = "payments"

    const openAccordion = (id) => {
        const accordion = document.getElementById(id)
        const panel = document.getElementById(`ind-${id}`)

        if (accordion.classList.contains('off')) {
            accordion.classList.remove('off')
            panel.textContent = '-'
            accordion.classList.add('fade-in')
        } else {
            accordion.classList.add('off')
            panel.textContent = '+'
        }
    }

    return (
        <main className='page-benefits'>
            <Navbar selectedPage={selectedPage} />
            <section className='content-benefits'>
                <h1>Formas de pagamentos</h1>
                <div onClick={() => openAccordion('id-1')} class="accordion">
                    <div><p>Condições de Pagamento através da App Store e Google Play</p></div>
                    <div><p id='ind-id-1' className='indicative'>-</p></div>
                </div>
                <div id='id-1' class="panel">
                    <Accordion1 />
                </div>
            </section>
        </main>
    )
}

export default Payments