import React from 'react'

const Accordion1 = () => (
    <>
        Dados da IØN Crypto da ZIYØN<br/><br/>
        <span className='bold'>Token:</span> 0x0c35c6D7c7A21f3299285840710437C50f608B19
        <br/><span className='bold'>Cadeia:</span> BNB Chain
        <br/><span className='bold'>Decimais:</span> 8 casas 
        <br/><span className='bold'>Liquidez:</span> $3.86k
        <br/><span className='bold'>Total Supply:</span> 100,000,000 ION
        <br/><span className='bold'>Preço medio:</span> $0.003
        <br/><span className='bold'>DEX:</span> Pancakeswap V2 (BSC)

        <br/><br/><span className='bold'>Gráfico Live:</span> <a href='https://www.geckoterminal.com/bsc/pools/0x243c877f6dc3e81c5b6a0bf40fa5ef37178ba0ac?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults' target="_blank" rel="noreferrer">{'https://geckoterminal.com'}</a> 
        <br/><span className='bold'>PancakeSwap:</span> <a href='https://pancakeswap.finance/swap?outputCurrency=0x0c35c6D7c7A21f3299285840710437C50f608B19' target="_blank" rel="noreferrer">{'https://pancakeswap.finance'}</a>
        <br/><span className='bold'>BscScan:</span> <a href='https://bscscan.com/token/0x0c35c6D7c7A21f3299285840710437C50f608B19' target="_blank" rel="noreferrer">{'https://bscscan.com'}</a>

        <br/><br/>
        <p>A ZIYØN (IØN) é uma criptomoeda construída na blockchain Binance Smart Chain (BSC), que visa fornecer uma solução eficiente e escalável para transferências de valor, contratos inteligentes e aplicativos descentralizados (dApps). A ZIYØN é baseado no padrão BEP-20 e oferece características como segurança, transparência e velocidade de transação. Este whitepaper apresenta uma visão detalhada do nosso ecossistema, incluindo seus recursos, casos de uso, roadmap de desenvolvimento.</p>
        <br/><br/><p><span className='bold'>Visão Geral</span></p><br/>
        <p>A ZIYØN (IØN) é uma moeda digital descentralizada que facilita transações peer-to-peer e contratos inteligentes na blockchain Binance Smart Chain. Com uma oferta total limitada de 100.000.000 IØN e 8 casas decimais, a ZIYØN visa fornecer um meio de troca eficiente e de baixo custo para usuários em todo o mundo. Ao utilizar a infraestrutura da BSC, a ZIYØN se beneficia da alta velocidade de transação e da escalabilidade da rede.</p>
        <br/><br/><p><span className='bold'>Características Principais</span></p><br/>
        <p>
        <span className='oblique'>01º Padrão BEP-20:</span> A ZIYØN é construído de acordo com o padrão BEP-20, o que significa que é compatível com carteiras e exchanges que suportam tokens BSC.
        <br/><br/><span className='oblique'>02º Segurança:</span> O contrato inteligente da ZIYØN implementa práticas de segurança robustas, mitigando riscos de ataques e garantindo a integridade dos fundos dos usuários.
        <br/><br/><span className='oblique'>03º Escalabilidade:</span> A Binance Smart Chain oferece alta escalabilidade, permitindo que a ZIYØN processe um grande volume de transações com tempos de confirmação rápidos e taxas mínimas.
        <br/><br/><span className='oblique'>04º Máximo de Transação:</span> A ZIYØN implementa um limite máximo de transação de 100 mil moedas para evitar manipulação de mercado e garantir a estabilidade do preço.
        </p>
        <br/><br/><p><span className='bold'>Caso de Uso</span></p><br/>
        <p>
        <span className='oblique'>01º Transferências de Valor:</span> Os usuários podem enviar e receber pagamentos instantâneos em qualquer parte do mundo usando a ZIYØN como meio de troca.
        <br/><br/><span className='oblique'>02º Contratos Inteligentes:</span> A ZIYØN permite a execução de contratos inteligentes, possibilitando uma variedade de casos de uso, incluindo tokens não fungíveis (NFTs), finanças descentralizadas (DeFi) e muito mais.
        <br/><br/><span className='oblique'>03º Pagamentos Online:</span> Empresas e comerciantes podem integrar a ZIYØN como uma opção de pagamento em suas plataformas, oferecendo uma alternativa segura e eficiente aos métodos tradicionais.
        </p>
        <br/><br/><p><span className='bold'>Roadmap de Desenvolvimento</span></p><br/>
        <p>
        <span className='oblique'>01º Fase de Lançamento:</span><br/><br/>✅ Distribuição inicial do token.<br/>⏱️ Pregresso - Listagem em exchanges.<br/>⏱️ Pregresso - Construção da comunidade.
        <br/><br/><span className='oblique'>02º Integração de Recursos:</span><br/>🎯 Adição de funcionalidades avançadas.<br/>🎯 Farming.<br/>🎯Swap.
        <br/><br/><span className='oblique'>03º Parcerias Estratégicas:</span><br/>🎯 Colaboração com outras plataformas.<br/>🎯 projetos para expandir o ecossistema.
        <br/><br/><span className='oblique'>04º Desenvolvimento de Aplicativos:</span><br/>🎯 Suporte ao desenvolvimento de dApps e NFTs. <br/>🎯 infraestrutura da ZIYØN.
        </p>
        <br/><br/><p><span className='bold'>Conclusão</span></p><br/>
        <p>
        A ZIYØN é uma criptomoeda projetada para oferecer uma solução eficiente e escalável para transferências de valor e contratos inteligentes na blockchain Binance Smart Chain. Com recursos de segurança robustos, escalabilidade e um modelo econômico deflacionário, a ZIYØN está bem posicionada para se tornar uma parte essencial do ecossistema cripto global.
        <br/><br/>Para mais informações e atualizações sobre a ZIYØN, visite nosso site e acompanhe nossos canais de mídia social.
        </p>
        <br/><br/><p><span className='bold'>Aviso Legal</span></p><br/>
        <p>Este documento é puramente informativo e não constitui aconselhamento financeiro ou de investimento. Os investidores devem realizar sua própria pesquisa e estar cientes dos riscos antes de tomar qualquer decisão de investimento em criptomoedas. A compra e venda de criptomoedas são atividades de alto risco, e os investidores devem estar preparados para possíveis perdas financeiras. A equipe por trás da ZIYØN não se responsabiliza por quaisquer perdas ou ganhos resultantes da compra, venda ou negociação de IØN ou outras criptomoedas.</p>
    </>
)

const Accordion2 = () => (
    <p>
    Para adicionar o token <span className='bold'>IØN da ZIYØN em sua carteira</span>, siga estas etapas resumidas:<br/><br/>

    01º - Abra sua carteira de criptomoedas que suporta tokens da Binance Smart Chain (BSC), como MetaMask, Trust Wallet ou Binance Chain Wallet.
    <br/><br/>02º - Clique na opção "Adicionar Token" ou "Adicionar Token Personalizado", dependendo da carteira que você está usando.
    <br/><br/>03º - Insira o endereço do contrato do token IØN. Você pode obter esse endereço em plataformas como BscScan ou no site oficial da ZIYØN.
    <br/><br/>04º - A carteira deve preencher automaticamente o nome do token (IØN) e o símbolo (IØN), mas você pode precisar inserir manualmente o número de casas decimais, que é 8 para o IØN.
    Confirme a adição do token à sua carteira.

    <br/><br/>Após seguir essas etapas, o token IØN da ZIYØN deve aparecer em sua carteira, permitindo que você visualize seu saldo e realize transações com ele. Certifique-se de verificar o endereço do contrato e as informações do token para garantir que você esteja adicionando o token correto à sua carteira.
    </p>
)

const Accordion3 = () => (
    <p>
        Para comprar o <span className='bold'>IØN Crypto da ZIYØN na PancakeSwap,</span> siga estes passos resumidos: <br/><br/>
        01º - Abra a PancakeSwap em seu navegador e conecte sua carteira de criptomoedas, como MetaMask, Binance Chain Wallet, ou Trust Wallet.
        <br/><br/>02º - Certifique-se de que sua carteira esteja conectada à Binance Smart Chain (BSC). Se não estiver, altere a rede para BSC nas configurações de sua carteira.
        <br/><br/>03º - Clique em "Trade" na barra de navegação superior e selecione "Exchange".
        <br/><br/>04º - No campo "De", escolha a criptomoeda que você deseja trocar pelo IØN. Digite o valor que deseja trocar.
        <br/><br/>05º - No campo "Para", selecione IØN e confirme o valor que deseja comprar.
        <br/><br/>06º - Ajuste as configurações de slippage (derrapagem) conforme necessário. Um valor de 1-2% geralmente é suficiente para a maioria das transações.
        <br/><br/>07º - Clique em "Swap" e depois em "Confirmar" na janela pop-up.
        08º - Aguarde a transação ser processada. Uma vez concluída, você receberá o IØN em sua carteira.
        
        <br/><br/>Lembre-se de revisar todas as transações cuidadosamente antes de confirmar e certifique-se de estar negociando na plataforma oficial da PancakeSwap para evitar golpes ou fraudes.
    </p>
)

export { Accordion1, Accordion2, Accordion3 }