// Assets.js
import ptStrings from './language/pt'
import frStrings from './language/fr'
import enStrings from './language/en'

export {
  ptStrings,
  frStrings,
  enStrings
}
