import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import './style/nav.css'
import './style/header.css'
import './style/benefits.css'
import './style/about.css'
import './style/price.css'
import './style/tools.css'
import './style/coin.css'
import './style/affilier.css'
import './style/footer.css'
import './style/people.css'

//language
import {
  ptStrings,
  frStrings,
  enStrings
} from '../../structure/Strings';

//video
import {
  large,
  small
} from '../../structure/Videos';

//profile
import {
  profile01,
  profile02,
  profile03,
  profile04
} from '../../structure/Profile';

//profile
import {
  home,
  homeSmall,
  taskTools,
  financeTools,
  budgetTools,
  calculatorTools,
  taskSmallTools,
  financeSmallTools,
  budgetSmallTools,
  calculatorSmallTools,
  coinTools,
  coinSmallTools,
  learnTools,
  learnSmallTools,
  partnerTools,
  partnerSmallTools,
  lawTools,
  lawSmallTools
} from '../../structure/Image';

//icons
import {
  brandIcon,
  menuIcon,
  closeIcon,
  playIcon,
  checkIcon,
  peopleIcon,
  targetIcon,
  timeIcon,
  checkedIcon,
  taskIcon,
  financeIcon,
  affilierIcon,
  coinsIcon,
  lawIcon,
  buyIcon,
  learnIcon,
  calculatorIcon,
  coinIcon,
  transfeer,
  society,
  graphic,
  bresilIcon,
  franceIcon,
  angleterreIcon,
  downIcon,
  rightIcon,
  downLineIcon,
  instaIcon,
  linkedIcon,
  youtubeIcon,
  discordIcon,
  appleBrand,
  appleBrandMobile,
  gooleBrand,
  world,
  affilier,
  pinterestIcon,
  tiktokIcon,
  xIcon
} from '../../structure/Icons';

function Home() {
  const screenWidth = window.innerWidth
  const isSmallScreen = screenWidth <= 575.98
  const [selectedLanguage, setSelectedLanguage] = useState('pt')
  const [strings, setStrings] = useState(ptStrings)

  const setLanguageStrings = (language) => {
    setSelectedLanguage(language)
    switch (language) {
      case 'pt':
        setFlagIcon(bresilIcon)
        setStrings(ptStrings)
        break;
      case 'fr':
        setFlagIcon(franceIcon)
        setStrings(frStrings)
        break;
      case 'en':
        setFlagIcon(angleterreIcon)
        setStrings(enStrings)
        break;
      default:
        setFlagIcon(bresilIcon)
        setStrings(ptStrings)
    }
  }

  const [showMenu, setShowMenu] = useState(false)
  const [showTools, setShowTools] = useState('task')
  const [activeTool, setActiveTool] = useState('task')
  const [isVideoOpen, setIsVideoOpen] = useState(false)

  // language
  const [portuguese, setPortuguese] = useState(true)
  const [french, setFrench] = useState(false)
  const [english, setEnglish] = useState(false)
  const [flagIcon, setFlagIcon] = useState()

  const saopaulo = 'Av. Paulista nº 1636 - São Paulo'
  const paris = '60 rue François 1er - Paris'
  const brasil = 'Brasil +55 11 937223060'
  const france = 'França +33 767 144 981'
  const email = 'contact@ziyon.fr'

  const copyright = '© 2024 Copyright'
  const siren = 'SIREN 97830392300012'

  const brand = 'ZIYØN SAS'

  const instaFR = 'https://www.instagram.com/ziyon.fr'
  const instaBR = 'https://www.instagram.com/ziyon.br'
  const [midia, setMidia] = useState('https://www.instagram.com/ziyon.br')

  const openMenu = () => {
    setShowMenu(true)
    document.addEventListener("DOMContentLoaded", function () {
      const sectionElement = document.getElementById('navig')
      sectionElement.classList.add('open')
      sectionElement.classList.remove('exit')
    })
  }

  const closeMenu = () => {
    const sectionElement = document.getElementById('navig')
    sectionElement.classList.remove('open')
    sectionElement.classList.add('exit')
    setTimeout(() => {
      setShowMenu(false)
    }, 350)
  }

  function drop() {
    document.getElementById("drop").classList.toggle("show")
  }

  window.onclick = function (event) {
    if (!event.target.matches('.flag')) {
      var dropdowns = document.getElementsByClassName("drop")
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show')
        }
      }
    }
  }

  const choiseLanguage = (lang) => {
    if (lang === 'pt') {
      setPortuguese(true)
      setFrench(false)
      setEnglish(false)
    } else if (lang === 'fr') {
      setPortuguese(false)
      setFrench(true)
      setEnglish(false)
    } else if (lang === 'en') {
      setPortuguese(false)
      setFrench(false)
      setEnglish(true)
    }
  }

  function scrollToSection(id) {
    var section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      closeMenu()
    }
  }

  function scrollToSec(id) {
    var section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleButtonClick = (tool) => {
    setShowTools(tool)
    setActiveTool(tool)
  }

  const handleReload = () => {
    window.location.reload()
  }

  function handlePlayClick() {
    setIsVideoOpen(true)
  }

  useEffect(() => {
    if (portuguese) {
      setLanguageStrings('pt')
    } else if (french) {
      setLanguageStrings('fr')
    } else if (english) {
      setLanguageStrings('en')
    }
  }, [portuguese, french, english])

  useEffect(() => {
    switch (selectedLanguage) {
      case 'pt':
        setStrings(ptStrings)
        setMidia(instaBR)
        break;
      case 'fr':
        setStrings(frStrings)
        setMidia(instaFR)
        break;
      case 'en':
        setStrings(enStrings)
        setMidia(instaFR)
        break;
      default:
        setStrings(ptStrings)
        setMidia(instaBR)
    }
  }, [selectedLanguage])

  useEffect(() => {
    choiseLanguage('pt')
    setLanguageStrings('pt')
    setSelectedLanguage('pt');
    setFlagIcon(bresilIcon)
    setShowTools('task')
    setActiveTool('task')
  }, [])

  return (
    <div>
      {/* nav mobile */}
      {showMenu && (
        <section id='navig' className='container navigation open'>
          <div>
            <div className='text-lang'>
              <div className='links-mobile'>
                {/* <p>{strings.coin}</p> */}
                <Link className='link-doc' to="/doc/coin">{<p>{strings.coin}</p>}</Link>
                <img loading="lazy" alt="right Icon" src={rightIcon} />
              </div>
              <div className='links-mobile'>
                <Link className='link-doc' to="/doc">
                  <p>{strings.doc}</p>
                  <img loading="lazy" alt="right Icon" src={rightIcon} />
                </Link>
              </div>
              <div onClick={() => scrollToSection('section-tools')} className='links-mobile'>
                <p>{strings.tools}</p>
                <img loading="lazy" alt="right Icon" src={rightIcon} />
              </div>
              <div onClick={() => scrollToSection('section-price')} className='links-mobile'>
                <p>{strings.price}</p>
                <img loading="lazy" alt="right Icon" src={rightIcon} />
              </div>
            </div>
            <div className='language-mobile'>
              <div className='lang-text'><p>{strings.language} ({strings.initials})</p></div>
              <div className='contant-lang'>
                <div className='flag-mobile' onClick={() => choiseLanguage('pt')}>
                  <div className='name-flag'>
                    <img loading="lazy" alt="bresi lIcon" src={bresilIcon} />
                    {portuguese && (
                      <p style={{ fontWeight: 'bold' }}>{strings.pt}</p>
                    )}
                    {!portuguese && (
                      <p>{strings.pt}</p>
                    )}
                  </div>
                  {portuguese && (
                    <div>
                      <img loading="lazy" alt="check Icon" src={checkIcon} />
                    </div>
                  )}
                </div>
                <div className='flag-mobile' onClick={() => choiseLanguage('fr')}>
                  <div className='name-flag'>
                    <img loading="lazy" alt="france Icon" src={franceIcon} />
                    {french && (
                      <p style={{ fontWeight: 'bold' }}>{strings.fr}</p>
                    )}
                    {!french && (
                      <p>{strings.fr}</p>
                    )}
                  </div>
                  {french && (
                    <div>
                      <img loading="lazy" alt="check Icon" src={checkIcon} />
                    </div>
                  )}
                </div>
                <div className='flag-mobile' onClick={() => choiseLanguage('en')}>
                  <div className='name-flag'>
                    <img loading="lazy" alt="angleterre Icon" src={angleterreIcon} />
                    {english && (
                      <p style={{ fontWeight: 'bold' }}>{strings.en}</p>
                    )}
                    {!english && (
                      <p>{strings.en}</p>
                    )}
                  </div>
                  {english && (
                    <div>
                      <img loading="lazy" alt="check Icon" src={checkIcon} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* navigation */}
      <nav>
        <div className='container menuBar'>
          {!showMenu && (
            <div className='menu' onClick={openMenu}><img loading="lazy" alt="Menu" src={menuIcon} /></div>
          )}
          {showMenu && (
            <div className='menu' onClick={closeMenu}><img loading="lazy" alt="Close" src={closeIcon} /></div>
          )}
          <div className='nav-content'>
            <div className='brand'><img loading="lazy" alt="brand Icon" onClick={handleReload} src={brandIcon} /></div>
            <div className='links'>
              <div className='txt-links'>
                <p className='link-nav' onClick={() => scrollToSec('section-tools')}>{strings.tools}</p>
                {/* <p className='link-nav' onClick={() => scrollToSec('section-coin')}>{navCoin}</p> */}
                <Link className='link-nav' to="/doc/coin">{<p>{strings.coin}</p>}</Link>
                <Link className='link-nav' to="/doc">{<p>{strings.doc}</p>}</Link>
                <p className='link-nav' onClick={() => scrollToSec('section-price')}>{strings.price}</p>
              </div>
              <div className='icons'>
                <div class="dropdown">
                  <div className='language'>
                    <img loading="lazy" alt="flag Icon" className='flag' src={flagIcon} onClick={() => drop()} />
                    <img loading="lazy" alt="down Icon" src={downIcon} onClick={() => drop()} />
                  </div>
                  <div id="drop" className='drop'>
                    <div class="dropdown-content">
                      <span onClick={() => setLanguageStrings('fr')}>
                        <img loading="lazy" alt="france Icon" className='flag' src={franceIcon} />
                        <p className=''>{strings.fr}</p>
                      </span>
                      <span onClick={() => setLanguageStrings('pt')} className='midle-flag'>
                        <img loading="lazy" alt="bresil Icon" className='flag' src={bresilIcon} />
                        <p className=''>{strings.pt}</p>
                      </span>
                      <span onClick={() => setLanguageStrings('en')}>
                        <img loading="lazy" alt="angleterre Icon" className='flag' src={angleterreIcon} />
                        <p className=''>{strings.en}</p>
                      </span>
                    </div>
                  </div>
                </div>
                <p className='line'>|</p>
                <span className='midias'>
                  <img loading="lazy" alt="insta Icon" onClick={() => window.open(midia, '_blank')} src={instaIcon} rel="noreferrer" />
                  <img loading="lazy" alt="youtube Icon" onClick={() => window.open('https://www.youtube.com/@ziyonsas', '_blank')} className='icon-mid' src={youtubeIcon} rel="noreferrer" />
                  <img loading="lazy" alt="x Icon" onClick={() => window.open('https://x.com/ziyonsas', '_blank')} src={xIcon} rel="noreferrer" />
                  <img className='whatsApp-nav' loading="lazy" alt="whatsApp Icon" onClick={() => window.open('https://wa.me/5511937223060', '_blank')} src={discordIcon} rel="noreferrer" />
                </span>
              </div>
            </div>
          </div>
          <div className='teaser'><img onClick={() => window.open('https://www.youtube.com/@ziyonsas', '_blank')} rel="noreferrer" loading="lazy" alt="play Icon" src={playIcon} /></div>
        </div>
      </nav>

      {/* header */}
      <header className='container'>
        <div id='header-left' className='left'>
          <p className='legend'>{strings.headerSubTitle}</p>
          <h1>{strings.headerTitle} <span className='accent'>{strings.headerTitleAccent}</span></h1>
          <p className='desc'>{strings.headerDesc}</p>
          <div className='btn-download'>
            <div className='apple-mobile' onClick={() => window.open('https://apps.apple.com/br/app/ziy%C3%B8n/id6472515772?l=en-GB', '_blank')} rel="noreferrer">
              {isSmallScreen ? (
                <div><img loading="lazy" alt="apple Brand" src={appleBrandMobile} /></div>
              ) : (
                <div><img loading="lazy" alt="apple Brand" src={appleBrand} /></div>
              )}
              <div>
                <p className='brand-legend'>{strings.availableApple}</p>
                <p className='brand-txt'>{strings.appleText}</p>
              </div>
            </div>
            <div className='google-mobile' onClick={() => window.open('https://play.google.com/store/apps/details?id=fr.ziyon', '_blank')} rel="noreferrer">
              <div><img loading="lazy" alt="goole Brand" src={gooleBrand} /></div>
              <div>
                <p className='brand-legend'>{strings.availableGoogle}</p>
                <p className='brand-txt'>{strings.googleText}</p>
              </div>
            </div>
          </div>
        </div>
        <div id='header-right' className='right'>
          {isSmallScreen ? (
            // Se a tela for pequena
            !isVideoOpen ? (
              // Se o vídeo não estiver aberto, exiba a imagem
              <div className='mockup'>
                <div> </div>
                <img
                  loading="lazy"
                  alt="home"
                  src={homeSmall}
                  onClick={handlePlayClick}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              // Se o vídeo estiver aberto, exiba o vídeo
              <video
                loading="lazy"
                autoPlay
                loop
                controls
              >
                <source src={small} type="video/mp4" />
              </video>
            )
          ) : (
            // Se a tela não for pequena
            !isVideoOpen ? (
              // Se o vídeo não estiver aberto, exiba a imagem
              <div className='mockup'>
                <div> </div>
                <img
                  loading="lazy"
                  alt="home"
                  src={home}
                  onClick={handlePlayClick}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              // Se o vídeo estiver aberto, exiba o vídeo
              <video
                loading="lazy"
                autoPlay
                loop
                controls
              >
                <source src={large} type="video/mp4" />
              </video>
            )
          )}
        </div>

      </header>

      {/* benefits */}
      <section className='benefits'>
        <div className='container cards'>
          <div className='card-benefits'>
            <div className='people-benefts'>
              <img loading="lazy" alt="people Icon" src={peopleIcon} />
              <p>{strings.onePeople}</p>
            </div>
            <div className='target'>
              <img loading="lazy" alt="target Icon" src={targetIcon} />
              <p>{strings.hundred}</p>
            </div>
            <div className='text-benefits'>
              <h1><span className='bold'>{strings.hundredTitleBold}</span> {strings.hundredTitle}</h1>
              <p className='desc-benefits'>{strings.hundredDesc}</p>
              <Link to="/doc/benefits/hundredthousand">
                <p className='btn-benefits'>{strings.benefitsTerms}</p>
              </Link>
            </div>
          </div>
          <div className='card-benefits'>
            <div className='people-benefts'>
              <img loading="lazy" alt="people Icon" src={peopleIcon} />
              <p>{strings.onePeople}</p>
            </div>
            <div className='target'>
              <img loading="lazy" alt="target Icon" src={targetIcon} />
              <p>{strings.fiveHundred}</p>
            </div>
            <div className='text-benefits'>
              <h1><span className='bold'>{strings.fiveHundredTitleBold}</span> {strings.fiveHundredTitle}</h1>
              <p className='desc-benefits'>{strings.fiveHundredDesc}</p>
              <Link to="/doc/benefits/fivehundredthousand">
                <p className='btn-benefits'>{strings.benefitsTerms}</p>
              </Link>
            </div>
          </div>
          <div className='card-benefits'>
            <div className='people-benefts'>
              <img loading="lazy" alt="people Icon" src={peopleIcon} />
              <p>{strings.towPeople}</p>
            </div>
            <div className='target'>
              <img loading="lazy" alt="target Icon" src={targetIcon} />
              <p>{strings.million}</p>
            </div>
            <div className='text-benefits'>
              <h1><span className='bold'>{strings.millionTitleBold}</span> {strings.millionTitle}</h1>
              <p className='desc-benefits'>{strings.millionDesc}</p>
              <Link to="/doc/benefits/million">
                <p className='btn-benefits'>{strings.benefitsTerms}</p>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* affilier */}
      <section className='affilier'>
        <div className='text-affilier left-affilier'><p>{strings.affilierLeftOne} <span className='bold'>{strings.affilierLeftTwo}</span> {strings.affilierLeftThree}
          <Link className='link-affilier' to="/doc/partner">{strings.affilierLeftFour}</Link></p></div>
        <div className='affilier-img'><img loading="lazy" alt="affilier" src={affilier} /></div>
        <div className='text-affilier right-affilier'>
          <p className='text-affilier-desc'>{strings.affilierDesc}</p>
          <p className='right-affilier-text'>{strings.affilierRightOne} <span className='bold'>{strings.affilierRightTwo}</span> {strings.affilierRightThree}</p></div>
      </section>

      {/* price */}
      <section id='section-price' className='section-price'>
        <div className='background-price'></div>
        <div className='container'>
          <div className='titles-price'>
            <h1 className='title-price'>{strings.priceTitle}</h1>
            <h2 className='subtitle-price'>{strings.priceSubtitle}</h2>
          </div>
          <div className='content-price'>
            {/* one */}
            <div className='card-price first-price'>
              <div>
                <div className='numb-price'>
                  <h1>R$119,90</h1>
                  <p>R$229,90</p>
                </div>
                <div className='time-price'>
                  <img loading="lazy" alt="time Icon" src={timeIcon} />
                  <p>{strings.priceTime}</p>
                </div>
                <p className='model-price'>{strings.priceModel}<span className='legend-price'>{strings.priceLifetime}</span></p>
                <p className='desc-price'>{strings.priceDesc}</p>
                <div className='line-price'></div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolTask}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolFinance}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolBudget}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolCalculator}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolLaw}</p>
                </div>
                <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolAffiliate}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolCoin}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolBuy}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolGraphic}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolCloud}</p>
              </div>
              </div>
              <div className='btn-download'>
                <div className='apple-mobile' onClick={() => window.open('https://apps.apple.com/br/app/ziy%C3%B8n/id6472515772?l=en-GB', '_blank')} rel="noreferrer">
                  <div><img loading="lazy" alt="apple Brand" src={appleBrand} /></div>
                  <div>
                    <p className='brand-legend'>{strings.availableApple}</p>
                    <p className='brand-txt'>{strings.appleText}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* two */}
            <div className='card-price mid-price'>
              <div>
              <div className='numb-price'>
                  <h1>R$119,90</h1>
                  <p>R$229,90</p>
                </div>
              <div className='time-price'>
                  <img loading="lazy" alt="time Icon" src={timeIcon} />
                  <p>{strings.priceTime}</p>
                </div>
              <p className='model-price'>{strings.priceModel}<span className='legend-price'>{strings.priceLifetime}</span></p>
              <p className='desc-price'>{strings.priceDesc}</p>
              <div className='line-price'></div>
              <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolTask}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolFinance}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolBudget}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolCalculator}</p>
                </div>
                <div className='item-price'>
                  <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                  <p>{strings.toolLaw}</p>
                </div>
                <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolAffiliate}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolCoin}</p>
              </div>
              </div>
              <div className='btn-download'>
                <div className='google-mobile' onClick={() => window.open('https://play.google.com/store/apps/details?id=fr.ziyon', '_blank')} rel="noreferrer">
                  <div><img loading="lazy" alt="goole Brand" src={gooleBrand} /></div>
                  <div>
                    <p className='brand-legend'>{strings.availableGoogle}</p>
                    <p className='brand-txt'>{strings.googleText}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* three */}
            <div className='card-price'>
              <div className='numb-price'>
                <h1>{strings.priceNext}</h1>
              </div>
              <div className='time-price no-img'>
                <p>{strings.priceSoon}</p>
              </div>
              <p className='model-price'>{strings.priceModel}<span className='legend-price'>{strings.priceLifetime}</span></p>
              <p className='desc-price'>{strings.priceDesc}</p>
              <div className='line-price'></div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.priceOld}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolIA}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolPodcast}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolLearn}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolShop}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolMedia}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolBank}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolInfest}</p>
              </div>
              <div className='item-price'>
                <img loading="lazy" alt="checked Icon" src={checkedIcon} />
                <p>{strings.toolNomad}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* tools */}
      <section id='section-tools' className='tools'>
        <div className='container content-tools'>
          <div className='picture-tools'>
            {isSmallScreen ? (
              // Se a tela for pequena
              (showTools === 'task' && (
                <img loading="lazy" alt="task" src={taskSmallTools} />
              )) ||
              (showTools === 'finance' && (
                <img loading="lazy" alt="finance" src={financeSmallTools} />
              )) ||
              (showTools === 'budget' && (
                <img loading="lazy" alt="budget" src={budgetSmallTools} />
              )) ||
              (showTools === 'calculator' && (
                <img loading="lazy" alt="calculator" src={calculatorSmallTools} />
              )) ||
              (showTools === 'affilier' && (
                <img loading="lazy" alt="affilier" src={partnerSmallTools} />
              )) ||
              (showTools === 'coins' && (
                <img loading="lazy" alt="coins" src={coinSmallTools} />
              )) ||
              (showTools === 'laws' && (
                <img loading="lazy" alt="laws" src={lawSmallTools} />
              )) ||
              (showTools === 'learn' && (
                <img loading="lazy" alt="learn" src={learnSmallTools} />
              ))
            ) : (
              // Se a tela não for pequena
              (showTools === 'task' && (
                <img loading="lazy" alt="task" src={taskTools} />
              )) ||
              (showTools === 'finance' && (
                <img loading="lazy" alt="finance" src={financeTools} />
              )) ||
              (showTools === 'budget' && (
                <img loading="lazy" alt="budget" src={budgetTools} />
              )) ||
              (showTools === 'calculator' && (
                <img loading="lazy" alt="calculator" src={calculatorTools} />
              )) ||
              (showTools === 'affilier' && (
                <img loading="lazy" alt="affilier" src={partnerTools} />
              )) ||
              (showTools === 'coins' && (
                <img loading="lazy" alt="coins" src={coinTools} />
              )) ||
              (showTools === 'laws' && (
                <img loading="lazy" alt="laws" src={lawTools} />
              )) ||
              (showTools === 'learn' && (
                <img loading="lazy" alt="learn" src={learnTools} />
              ))
            )}
          </div>
          <div className='text-tools'>
            <div className='titles-tools'>
              <p>{strings.toDoTitle}</p>
              <h1>{strings.toDoSubtitle}</h1>
            </div>
            <div className='btn-content'>
              <div id='task' className={`btn-tools tool-task ${activeTool === 'task' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('task')}>
                <img loading="lazy" alt="task Icon" src={taskIcon} />
                <p>{strings.toDoTask}</p>
              </div>
              <div id='finance' className={`btn-tools tool-finance ${activeTool === 'finance' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('finance')}>
                <img loading="lazy" alt="finance Icon" src={financeIcon} />
                <p>{strings.toDoFinance}</p>
              </div>
              <div id='budget' className={`btn-tools tool-budget ${activeTool === 'budget' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('budget')}>
                <img loading="lazy" alt="shopping Icon" src={buyIcon} />
                <p>{strings.toDoBudget}</p>
              </div>
              <div id='calculator' className={`btn-tools tool-calculator ${activeTool === 'calculator' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('calculator')}>
                <img loading="lazy" alt="calculator Icon" src={calculatorIcon} />
                <p>{strings.toDoCalculator}</p>
              </div>
            </div>
            <div className='btn-content dow-menu'>
              <div id='affilier' className={`btn-tools tool-task ${activeTool === 'affilier' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('affilier')}>
                <img loading="lazy" alt="affilier Icon" src={affilierIcon} />
                <p>{strings.menuAffiliate}</p>
              </div>
              <div id='coins' className={`btn-tools tool-finance ${activeTool === 'coins' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('coins')}>
                <img loading="lazy" alt="coins Icon" src={coinsIcon} />
                <p>{strings.menuCoin}</p>
              </div>
              <div id='laws' className={`btn-tools tool-budget ${activeTool === 'laws' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('laws')}>
                <img loading="lazy" alt="laws Icon" src={lawIcon} />
                <p>{strings.menuLaw}</p>
              </div>
              <div id='learn' className={`btn-tools tool-calculator ${activeTool === 'learn' ? 'active-tools' : ''}`} onClick={() => handleButtonClick('learn')}>
                <img loading="lazy" alt="learn Icon" src={learnIcon} />
                <p>{strings.menuLearn}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* coin */}
      <section id='section-coin' className='section-coin'>
        <div className='bkg-coin'></div>
        <div className='only-content'>
          <img loading="lazy" className='world' alt="world" src={world} />
          <h1>{strings.coinTitle}</h1>
          <p className='coin-desc'>{strings.coinSubtitle}</p>
          <img loading="lazy" alt="coin Icon" className='coin-icon' src={coinIcon} />
          <img loading="lazy" alt="down LineIcon" className='coin-arrow' src={downLineIcon} />
        </div>
        <div className='line-coin'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="1655" viewBox="0 0 2 1655" fill="none">
            <path d="M1.00293 0L1.00313 1655" stroke="black" stroke-width="2" stroke-dasharray="20 20" />
          </svg>
        </div>
        <div className='content-coin'>
          <div className='coin-left'>
            <div className='text-coin first-coin'>
              <div className='circle circle-left'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <circle cx="16" cy="16" r="11.5" fill="black" stroke="black" />
                  <circle cx="16" cy="16" r="15.5" stroke="black" />
                </svg>
              </div>
              <div className='div-coin'>
                <img loading="lazy" alt="transfeer" src={transfeer} />
                <h2>{strings.coinTransfer}</h2>
                <p>{strings.coinTransferDesc}</p>
              </div>
            </div>
            <div className='text-coin second-coin'>
              <div className='circle circle-left'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <circle cx="16" cy="16" r="11.5" fill="black" stroke="black" />
                  <circle cx="16" cy="16" r="15.5" stroke="black" />
                </svg>
              </div>
              <div className='div-coin'>
                <img loading="lazy" alt="society" src={society} />
                <h2>{strings.coinSociety}</h2>
                <p>{strings.coinSocietyDesc}</p>
              </div>
            </div>
          </div>
          <div className='coin-right three-coin'>
            <div className='text-coin'>
              <div className='circle circle-right'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <circle cx="16" cy="16" r="11.5" fill="black" stroke="black" />
                  <circle cx="16" cy="16" r="15.5" stroke="black" />
                </svg>
              </div>
              <div className='div-coin'>
                <img loading="lazy" alt="graphic" src={graphic} />
                <h2>{strings.coinGraphic}</h2>
                <p>{strings.coinGraphicDesc}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* people */}
      <section className='people'>
        <div class="slider">
          <input type="radio" name="slider" title="slide1" checked="checked" class="slider__nav" />
          <input type="radio" name="slider" title="slide2" class="slider__nav" />
          <input type="radio" name="slider" title="slide3" class="slider__nav" />
          <input type="radio" name="slider" title="slide4" class="slider__nav" />
          <div class="slider__inner">
            <div class="slider__contents">
              <img src={profile01} alt="Jhoē" />
              <h2 class="slider__caption">Jhoē Marttin</h2>
              <p class="slider__txt">Como fundador da ziyøn, estou imensamente feliz em ajudar as pessoas a transformarem suas vidas através do nosso ecossistema.</p>
              <h5 onClick={() => window.open('https://www.instagram.com/jhoemarttin', '_blank')} rel="noreferrer">@jhoemarttin</h5>
            </div>
            <div class="slider__contents">
              <img src={profile02} alt="Leon" />
              <h2 class="slider__caption">Leon Fernandes</h2>
              <p class="slider__txt">Sendo cofundador da ziyøn, sinto uma imensa alegria em ver como nossa plataforma impacta positivamente a vida das pessoas.</p>
              <h5 onClick={() => window.open('https://www.instagram.com/leon_salvatore', '_blank')} rel="noreferrer">@leon_salvatore</h5>
            </div>
            <div class="slider__contents">
              <img src={profile03} alt="Felipe" />
              <h2 class="slider__caption">Felipe Nascimento</h2>
              <p class="slider__txt">Como diretor de tecnologia na ziyøn, estou imensamente satisfeito em ver nossa plataforma como uma das poucas no mundo com sistemas 100% atualizados com o mercado.</p>
              <h5 onClick={() => window.open('https://www.instagram.com/fenascimento.__', '_blank')} rel="noreferrer">@fenascimento</h5>
            </div>
            <div class="slider__contents">
              <img src={profile04} alt="Jefferson" />
              <h2 class="slider__caption">Jefferson Corrêa</h2>
              <p class="slider__txt">Como líder do projeto, estou contente em ver nossa equipe cumprindo com maestria seu papel, pois nossos clientes já estão atingindo seus objetivos.</p>
              <h5 onClick={() => window.open('https://www.instagram.com', '_blank')} rel="noreferrer">@jeff_correa</h5>
            </div>
          </div>
        </div>
      </section>

      {/* footer */}
      <footer className='container'>
        <div className='enterprise'>
          <h4>{brand}</h4>
          <p>{strings.descBrand}</p>
        </div>
        <div className='policies'>
          <h4>{strings.policies}</h4>
          <Link to="/doc/benefits/hundredthousand">{strings.policiesOne}</Link>
          <Link to="/doc/benefits/fivehundredthousand">{strings.policiesTwo}</Link>
          <Link to="/doc/benefits/million">{strings.policiesThree}</Link>
          <Link to="/doc/terms">{strings.policiesFour}</Link>
          <Link to="/doc/purchase">{strings.policiesFive}</Link>
        </div>
        <div className='contact'>
          <div className='contact-link'>
            <h4>{strings.contact}</h4>
            <a href='https://www.google.com/maps/place/Av.+Paulista,+1636+-+sala,+1504+-+Cerqueira+César,+São+Paulo+-+SP,+01310-200/@-23.56091,-46.656217,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce59ceac541131:0x133777386f0f4c90!8m2!3d-23.56091!4d-46.656217!16s%2Fg%2F11sx5qv9vn?entry=ttu' target="_blank" rel="noreferrer">{saopaulo}</a>
            <a href='https://www.google.com/maps/place/60+Rue+François+1er,+75008+Paris,+França/@48.8698381,2.3019217,20z/data=!4m6!3m5!1s0x47e66fc260aad4bf:0xea2ec6b3ab78791a!8m2!3d48.8698965!4d2.302023!16s%2Fg%2F11c5myk1c1?entry=ttu' target="_blank" rel="noreferrer">{paris}</a>
            <a href='tel:+5511937223060' target="_blank" rel="noreferrer">{brasil}</a>
            <a href='tel:+33767144981' target="_blank" rel="noreferrer">{france}</a>
            <a href='mailto:contact@ziyon.fr' target="_blank" rel="noreferrer">{email}</a>
          </div>
          <div className='midias'>
            <img loading="lazy" alt="insta Icon" onClick={() => window.open(midia, '_blank')} rel="noreferrer" src={instaIcon} />
            <img loading="lazy" alt="linked Icon" onClick={() => window.open('https://www.linkedin.com/company/ziyøn', '_blank')} rel="noreferrer" className='icon-mid' src={linkedIcon} />
            <img loading="lazy" alt="youtube Icon" onClick={() => window.open('https://www.youtube.com/@ziyonsas', '_blank')} rel="noreferrer" src={youtubeIcon} />
            <img loading="lazy" alt="discord Icon" onClick={() => window.open('https://wa.me/5511937223060', '_blank')} rel="noreferrer" className='icon-whatsApp' src={discordIcon} />
            <img loading="lazy" alt="pinterest Icon" onClick={() => window.open('https://br.pinterest.com/ziyonsas', '_blank')} rel="noreferrer" className='icon-whatsApp' src={pinterestIcon} />
            <img loading="lazy" alt="tiktok Icon" onClick={() => window.open('www.tiktok.com/@ziyonsas', '_blank')} rel="noreferrer" className='icon-whatsApp' src={tiktokIcon} />
            <img loading="lazy" alt="x Icon" onClick={() => window.open('https://x.com/ziyonsas', '_blank')} rel="noreferrer" className='icon-whatsApp' src={xIcon} />
          </div>
        </div>
      </footer>

      {/* copyright */}
      <section className='copyright'>
        <p>{copyright} <span className='copy-brand' onClick={handleReload}>{brand}</span></p>
        <p className='siren'>{siren}</p>
      </section>
    </div>
  )
}

export default Home
