import React from 'react'

const Accordion1 = () => (
    <p>
        Mais do que uma simples plataforma, a ZIYØN é um símbolo de excelência e sofisticação,
        redefinindo o estilo de vida pessoal e financeiro. Em um conceito moderno que transcende a mera gestão financeira,
        investimentos e realização de sonhos, somos um ecossistema único, onde é possível centralizar praticamente todas as suas atividades.
        Educação, gestão pessoal e financeira, investimentos, serviços bancários, direito e uma variedade de outras
        ferramentas e serviços estão ao seu alcance em um só lugar. Estamos desafiando as convenções estabelecidas no mercado,
        buscando a máxima autonomia para nossos usuários. Nossas ferramentas mais valiosas e utilizadas são vitalícias,
        refletindo nosso compromisso com a durabilidade e a qualidade.<br /><br /><span className='bold'>CEO & COO:</span><br />Em um mundo saturado e confuso,
        onde somos constantemente bombardeados por informações irrelevantes, dedicamos nossas vidas a construir um ecossistema sólido e útil.
        A ZIYØN não é apenas uma plataforma, é um espaço onde as pessoas podem se conectar, crescer e realizar seus sonhos.
        Acreditamos que o crescimento pessoal, quando alinhado a recursos sólidos, informações e serviços de qualidade,
        leva inevitavelmente à evolução. Queremos alertar você sobre a preciosidade do tempo; não permita que ninguém o controle.
        Cada momento perdido é uma oportunidade que se vai. As portas de nossa plataforma estão abertas agora; não perca a chance
        de fazer parte dela e garantir seu lugar.
    </p>
)

const Accordion2 = () => (
    <p>
        A finalidade da plataforma ZIYØN é tornar o acesso a ferramentas e serviços excepcionais mais fácil do que nunca.
        Através dela, você terá à disposição serviços incríveis que auxiliam na gestão da sua vida pessoal, financeira e
        oferecem oportunidades de educação em diversas áreas. Além disso, nossa loja virtual conta com produtos físicos
        projetados para atender às suas necessidades, abrangendo desde serviços financeiros até saúde, direito e uma variedade
        de outras ferramentas e serviços.
        <br /><br />
        ⚠️ <span className='bold'>Atenção!</span> Nossos serviços e produtos são disponibilizados ao longo das diferentes versões do aplicativo.
        Antes de fazer parte deste conceito, recomendamos verificar se a ferramenta desejada está incluída na versão atual.
    </p>
)

const Accordion3 = () => (
    <p>
        Nossas interfaces são altamente intuitivas, proporcionando uma experiência de usuário descomplicada. Para começar, localize nosso aplicativo na loja Google Play ou na App Store da Apple. Após o download, abra o aplicativo e forneça seu nome, número de telefone e e-mail. Em seguida, aceite os termos e condições do aplicativo para acessar a tela principal.
        <br /><br />
        Na parte superior da tela principal, você encontrará um menu de ferramentas rolável. Aqui, você poderá acessar todas as funcionalidades disponíveis no aplicativo de forma fácil e rápida. Se estiver interessado na seção dedicada ao mundo jurídico, basta rolar a página até o final. Lá, você encontrará o acesso direto a uma ferramenta especializada em conhecimento na área jurídica.
        <br /><br />
        Estamos constantemente aprimorando nossa plataforma para garantir que sua experiência seja ainda mais eficiente e agradável. Agradecemos por escolher nosso aplicativo e estamos aqui para proporcionar a você a melhor experiência possível.
    </p>
)

const Accordion4 = () => (
    <p>
        Explore um novo patamar de experiência com o aplicativo ZIYØN, uma plataforma premium que redefine o significado de exclusividade. Ao optar pela ZIYØN, você não apenas adquire um aplicativo, mas faz parte de uma comunidade exclusiva desde o início, onde todos os membros são recebidos com grandeza.
        <br /><br />
        Diferenciando-se dos modelos convencionais, o ZIYØN oferece um plano único e vitalício, proporcionando acesso irrestrito e vitalício a quase todas as funcionalidades do aplicativo. Essa abordagem inovadora elimina a preocupação com renovações constantes e oferece a você uma experiência sem interrupções, garantindo que seu investimento seja duradouro.
        <br /><br />
        No conceito ZIYØN, o foco está na qualidade, não na quantidade. Nossos serviços são exclusivos e limitados, destinados apenas àqueles que se tornam parte desta comunidade distinta. Ao escolher a ZIYØN, você se junta a uma elite que valoriza a excelência e busca uma experiência inigualável.
    </p>
)

const Accordion5 = () => (
    <p>
        Na ZIYØN a excelência encontra durabilidade! A palavra-chave aqui é "vitalício". Acreditamos em oferecer uma experiência sem preocupações, e é por isso que a grande maioria dos nossos produtos e serviços são disponibilizados com uma única taxa de pagamento, garantindo que você desfrute de benefícios duradouros sem a necessidade de renovações constantes.
        <br /><br />
        Ao escolher a ZIYØN, você investe em um ecossistema digital onde a simplicidade e a longevidade se encontram. Muitos dos nossos serviços são oferecidos com um pagamento único, proporcionando uma relação custo-benefício incomparável.
        <br /><br />
        É importante destacar que, enquanto a maioria dos nossos produtos é vitalício, reconhecemos que existem necessidades específicas para as quais oferecemos serviços por assinatura. Essa abordagem flexível permite que você escolha exatamente o que se alinha com suas preferências e requisitos.
        <br /><br />
        Para esclarecer, a aquisição do nosso aplicativo é uma garantia vitalícia. Uma vez adquirido, o aplicativo será seu indefinidamente, e você desfrutará de acesso vitalício a todas as ferramentas não pagas contidas nele. Contudo, é importante ressaltar que, embora a propriedade seja vitalícia, os valores podem sofrer ajustes para novas funcionalidades ou versões.
        <br /><br />
        Nossa política de precificação envolve aumentos que, em média, refletem o dobro do valor atual em cada nova funcionalidade ou versão. Recomendamos que aproveite a oportunidade de adquirir o aplicativo o quanto antes, pois o valor está sujeito a aumentos ao longo dos lançamentos. 
        <br /><br />
        <span className='oblique'>É relevante destacar que, para os usuários que já adquiriram o aplicativo, não haverá custos adicionais para receber as atualizações. Uma vez que você comprou a plataforma, terá acesso a todas as melhorias subsequentes sem custo adicional, exceto para serviços específicos que possam ser oferecidos dentro do aplicativo.</span>
    </p>
)

const Accordion6 = () => (
    <p>
        Dentro do nosso aplicativo, a maior parte dos recursos está disponível sem custo adicional, proporcionando uma experiência robusta e rica sem a necessidade de pagamentos. Valorizamos cada cliente, e nosso compromisso é garantir que a essência do aplicativo seja acessível.
        <br /><br />
        Além disso, para atender a necessidades mais específicas, disponibilizamos produtos e serviços pagos, todos eles com caráter pontual. Estes serviços, acessíveis através de um pagamento único, oferecem benefícios vitalícios, proporcionando uma experiência única e personalizada para os usuários que desejam explorar funcionalidades adicionais. 
        Além disso, oferecemos uma variedade de produtos por assinatura, como o plano internacional de hospedagem e serviços relacionados à seguro e saúde, proporcionando opções flexíveis para atender a diversas necessidades.
    </p>
)

const Accordion7 = () => (
    <p>
        Nosso aplicativo segue uma abordagem dinâmica para melhorias, sem datas fixas de lançamento. Contudo, nossa meta é apresentar aprimoramentos a cada 45 dias, seja introduzindo novas ferramentas empolgantes ou realizando correções essenciais. Essa abordagem flexível permite uma adaptação contínua às necessidades dos usuários, garantindo uma experiência consistente e inovadora.
    </p>
)

const Accordion8 = () => (
    <p>
        Queremos esclarecer que, embora a vitaliciedade da nossa aplicação seja inabalável, seu valor pode ser ajustado ao longo das novas versões e metas estabelecidas. <span className='oblique'>É fundamental salientar que essas mudanças de preço não afetarão os clientes existentes</span>, garantindo que sua decisão de adquirir a plataforma permaneça um investimento sólido.
        <br /><br />
        Recomendamos que você aproveite a oportunidade de adquirir a aplicação o mais cedo possível, pois isso lhe proporcionará um valor mais acessível. Essa abordagem permite que novos clientes desfrutem de preços mais vantajosos, enquanto nossos clientes existentes continuam a usufruir das vantagens de sua aquisição inicial.
    </p>
)

const Accordion9 = () => (
    <p>
        Colocando a segurança e a privacidade em primeiro lugar! Na versão inaugural, queremos garantir total transparência ao afirmar que nenhum dado, exceto NOME, E-MAIL e TELEFONE, será salvo ou compartilhado. Isso permite que você tenha a opção de decidir se deseja compartilhar essas informações ou não. Priorizamos a confidencialidade dos seus dados e, por isso, na primeira versão, todas as informações das ferramentas são armazenadas exclusivamente no dispositivo do usuário.
        <br /><br />
        A partir da próxima versão em diante, estamos empolgados em oferecer uma nova dimensão de funcionalidade. Nossos clientes terão a opção de compartilhar dados entre si, graças ao armazenamento seguro em nuvem. Este avanço proporcionará uma experiência colaborativa e enriquecedora, possibilitando a troca de informações de maneira eficiente.
        <br /><br />
        Entendemos a importância da segurança dos dados e queremos garantir que cada cliente tenha controle sobre suas informações. Na primeira versão, os dados permanecerão exclusivamente no dispositivo, mas na segunda versão e posteriores, a nuvem se tornará uma aliada para a conveniência e a interconexão.
    </p>
)

const Accordion10 = () => (
    <p>
        Desde a primeira versão, garantimos que o acesso à plataforma seja exclusivo para o detentor da compra, oferecendo uma camada extra de proteção aos seus dados e informações.
        <br /><br />
        Na segunda versão, mantemos essa ideia central de segurança, permitindo que o usuário tenha a flexibilidade de criar dois perfis dependentes. Apesar dessa adição, o acesso continuará a ocorrer unicamente pelo dispositivo do detentor da compra, garantindo um controle total sobre quem pode acessar a plataforma.
        <br /><br />
        Os perfis criados têm como objetivo diferenciar entre o uso pessoal e empresarial ou familiar, proporcionando uma experiência personalizada para cada necessidade. Esta abordagem visa oferecer versatilidade ao usuário, sem comprometer a segurança e o controle de acesso.
    </p>
)

const Accordion11 = () => (
    <p>
        Estamos aqui para você! Se por acaso não encontrou a resposta que procura, fique à vontade para entrar em contato conosco e esclarecer suas dúvidas.
        <br /><br />
        No Discord, temos canais específicos para atender às suas necessidades:
        <br /><br />
        Para suporte, <a href='https://discord.gg/9YV6axrzX7' target="_blank" rel="noreferrer">{'Falar com suporte'}</a> <br/>
        Para questões relacionadas a vendas, <a href='https://discord.gg/DhUbANfWMW' target="_blank" rel="noreferrer">{'Falar com financeiro'}</a> <br/>
        Se estiver interessado em oportunidades de recrutamento, <a href='https://discord.gg/5tcqwCR2WY' target="_blank" rel="noreferrer">{'Falar com recrutadores'}</a> <br/>
        <br /><br />
        Caso prefira nos contatar por e-mail, estamos disponíveis nos seguintes endereços:
        <br /><br />
        Para suporte, envie um e-mail para <a href='mailto:support@ziyon.fr' target="_blank" rel="noreferrer">{'support@ziyon.fr'}</a><br/>
        Questões financeiras podem ser direcionadas para <a href='mailto:contact@ziyon.fr' target="_blank" rel="noreferrer">{'contact@ziyon.fr'}</a><br/>
        <br /><br />
        Estamos ansiosos para ajudar e garantir que sua experiência conosco seja a melhor possível, não hesite em entrar em contato.
    </p>
)

export { Accordion1, Accordion2, Accordion3, Accordion4, Accordion5, Accordion6, Accordion7, Accordion8, Accordion9, Accordion10, Accordion11 }