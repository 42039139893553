// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* content */

main {
  height: 100vh;
}

.page-benefits {
  display: flex;
}

.page-benefits h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
  padding-top: 160px;
}

.content-benefits {
  overflow-y: scroll;
  width: 775px;
  margin-left: 60px;
}

.content-benefits::-webkit-scrollbar {
  display: none;
}

.terms-update {
  margin-top: -20px;
  margin-bottom: 30px;
}


/* accordion */

.accordion {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-top: solid 1px #00000031;
}

.active,
.accordion:hover {
  font-weight: 600 !important;
}

.indicative {
  font-size: 18px;
}

.panel {
  padding-bottom: 40px;
}

.off {
  display: none;
}

/* @media (max-width: 1100.98px) {}

@media (max-width: 991.98px) {} */

@media (max-width: 575.98px) {
  .content-benefits {
    width: 100%;
    margin-left: 0px;
    padding: 0 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/source/Documentation/components/style/accordion.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;;AAGA,cAAc;;AAEd;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,+BAA+B;AACjC;;AAEA;;EAEE,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;;iCAEiC;;AAEjC;EACE;IACE,WAAW;IACX,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":["/* content */\n\nmain {\n  height: 100vh;\n}\n\n.page-benefits {\n  display: flex;\n}\n\n.page-benefits h1 {\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  margin-bottom: 30px;\n  padding-top: 160px;\n}\n\n.content-benefits {\n  overflow-y: scroll;\n  width: 775px;\n  margin-left: 60px;\n}\n\n.content-benefits::-webkit-scrollbar {\n  display: none;\n}\n\n.terms-update {\n  margin-top: -20px;\n  margin-bottom: 30px;\n}\n\n\n/* accordion */\n\n.accordion {\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 70px;\n  border-top: solid 1px #00000031;\n}\n\n.active,\n.accordion:hover {\n  font-weight: 600 !important;\n}\n\n.indicative {\n  font-size: 18px;\n}\n\n.panel {\n  padding-bottom: 40px;\n}\n\n.off {\n  display: none;\n}\n\n/* @media (max-width: 1100.98px) {}\n\n@media (max-width: 991.98px) {} */\n\n@media (max-width: 575.98px) {\n  .content-benefits {\n    width: 100%;\n    margin-left: 0px;\n    padding: 0 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
