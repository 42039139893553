import brandIcon from '../assets/svg/ziyøn.svg'

import menuIcon from '../assets/svg/menu.svg'
import closeIcon from '../assets/svg/xmark.svg'
import playIcon from '../assets/svg/play.svg'
import checkIcon from '../assets/svg/check.svg'
import peopleIcon from '../assets/svg/people.svg'
import targetIcon from '../assets/svg/target.svg'
import timeIcon from '../assets/svg/time.svg'
import checkedIcon from '../assets/svg/checked.svg'
import taskIcon from '../assets/svg/ic-task.svg'
import financeIcon from '../assets/svg/ic-finance.svg'
import affilierIcon from '../assets/svg/ic-affiliate.svg'
import coinsIcon from '../assets/svg/ic-coin.svg'
import lawIcon from '../assets/svg/ic-law.svg'
import buyIcon from '../assets/svg/ic-buy.svg'
import shoppingIcon from '../assets/svg/ic-shopping.svg'
import learnIcon from '../assets/svg/ic-school.svg'
import calculatorIcon from '../assets/svg/ic-calculator.svg'
import coinIcon from '../assets/svg/globecoin2.svg'
import transfeer from '../assets/svg/transfer.svg'
import society from '../assets/svg/society.svg'
import graphic from '../assets/svg/graphic.svg'

import bresilIcon from '../assets/svg/bresil.svg'
import franceIcon from '../assets/svg/france.svg'
import angleterreIcon from '../assets/svg/angleterre.svg'

import downIcon from '../assets/svg/arrowdown.svg'
import rightIcon from '../assets/svg/arrowdright.svg'
import downLineIcon from '../assets/svg/ic-down2.svg'

import instaIcon from '../assets/svg/instagram.svg'
import linkedIcon from '../assets/svg/linkedin.svg'
import youtubeIcon from '../assets/svg/youtube.svg'
import discordIcon from '../assets/svg/whatsappfill.svg'
import pinterestIcon from '../assets/svg/pinterest.svg'
import tiktokIcon from '../assets/svg/tiktok.svg'
import xIcon from '../assets/svg/x.svg'

import appleBrand from '../assets/svg/brandapple.svg'
import appleBrandMobile from '../assets/svg/brandappleMobile.svg'
import gooleBrand from '../assets/svg/brandgoogle.svg'

import world from '../assets/svg/monde.svg'
import affilier from '../assets/img/affilier.png'

import home from '../assets/img/home.png'

export {
  brandIcon,
  menuIcon,
  closeIcon,
  playIcon,
  checkIcon,
  peopleIcon,
  targetIcon,
  timeIcon,
  checkedIcon,
  taskIcon,
  financeIcon,
  shoppingIcon,
  affilierIcon,
  coinsIcon,
  lawIcon,
  buyIcon,
  learnIcon,
  calculatorIcon,
  coinIcon,
  transfeer,
  society,
  graphic,
  bresilIcon,
  franceIcon,
  angleterreIcon,
  downIcon,
  rightIcon,
  downLineIcon,
  instaIcon,
  linkedIcon,
  youtubeIcon,
  discordIcon,
  appleBrand,
  appleBrandMobile,
  gooleBrand,
  world,
  affilier,
  home,
  pinterestIcon,
  tiktokIcon,
  xIcon
}
