import React from 'react'

const Accordion1 = () => (
  <>
    <p>
    Abaixo, apresentamos nossos canais oficiais de comunicação e plataformas nas quais realizamos nosso atendimento. Queremos assegurar-lhe que nosso suporte é totalmente humano, pois valorizamos a oportunidade de resolver qualquer dificuldade ou problema diretamente com você.
      <br/><br/>
      ⚠️ Gostaríamos de esclarecer que em nenhuma circunstância solicitamos dados pessoais, como <span className='bold'>Passaporte, ID, CPF,</span> ou informações de pagamento, incluindo <span className='bold'>transferências, boletos ou cartões de crédito,</span> por e-mail, ligações ou qualquer outro meio.
      <br/> <br/>
        Sua segurança é de extrema importância para nós. Se houver qualquer dúvida ou se você receber alguma solicitação suspeita em nosso nome, pedimos que entre em contato diretamente através dos seguintes canais:
      <br/><br/><br/>
      <span className='bold'>WhatsApp:</span> <br/>
      - Financeiro e Suporte BR: <a href='https://wa.me/5511937223060' target="_blank" rel="noreferrer">{'+55 11 93722 3060'}</a><br/>
      - Financeiro e Suporte FR: Em breve <br/>
      <br/> <br/>
      <span className='bold'>E-mail:</span> <br/>
      - Canal de suporte: <a href='mailto:support@ziyon.fr' target="_blank" rel="noreferrer">{'support@ziyon.fr'}</a><br/>
      - Canal financeiro: <a href='mailto:contact@ziyon.fr' target="_blank" rel="noreferrer">{'contact@ziyon.fr'}</a><br/>
      <br/> <br/>
      <span className='bold'>Mídias sociais:</span> <br/>
      - Instagram:  <a href='https://www.instagram.com/ziyon.fr' target="_blank" rel="noreferrer">{'Instagram fr'}</a> <br/>
      - Instagram:  <a href='https://www.instagram.com/ziyon.br' target="_blank" rel="noreferrer">{'Instagram br'}</a> <br/>
      - Linkedin: <a href='https://www.linkedin.com/company/ziyøn' target="_blank" rel="noreferrer">{'Linkedin'}</a> <br/>
      - Discord: <a href='https://discord.gg/f89nFkmDNp' target="_blank" rel="noreferrer">{'Discord'}</a> <br/>
      - Youtube: <a href='https://www.youtube.com/@ziyonsas' target="_blank" rel="noreferrer">{'Youtube'}</a> <br/>
      - Facebook: <a href='https://www.facebook.com/ziyonsas' target="_blank" rel="noreferrer">{'Facebook'}</a> <br/>
      - Pinterest: <a href='https://br.pinterest.com/ziyonsas' target="_blank" rel="noreferrer">{'Pinterest'}</a> <br/>
      - TikTok: <a href='www.tiktok.com/@ziyonsas' target="_blank" rel="noreferrer">{'TikTok'}</a> <br/>
      - X: <a href='https://x.com/ziyonsas?s=21&t=Xau1btZD1kqGoh8MiKgIzQ' target="_blank" rel="noreferrer">{'X (Twitter)'}</a> <br/>
    </p>
  </>
)

export { Accordion1 }