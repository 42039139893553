import React from 'react'
import Navbar from './../../../components/Navigation'

import { Accordion3 } from './strings/Portuguese'

import '../../../components/style/accordion.css'
import '../../../../../structure/css/anim.css'

function FiveHundred() {
    const selectedPage = "benefits"

    const openAccordion = (id) => {
        const accordion = document.getElementById(id)
        const panel = document.getElementById(`ind-${id}`)

        if (accordion.classList.contains('off')) {
            accordion.classList.remove('off')
            panel.textContent = '-'
            accordion.classList.add('fade-in')
        } else {
            accordion.classList.add('off')
            panel.textContent = '+'
        }
    }

    return (
        <main className='page-benefits'>
            <Navbar selectedPage={selectedPage} />
            <section className='content-benefits'>
                <h1>Benefícios e metas</h1>
                <div onClick={() => openAccordion('id-3')} class="accordion">
                    <div><p>500 mil downloads</p></div>
                    <div><p id='ind-id-3' className='indicative'>-</p></div>
                </div>
                <div id='id-3' class="panel">
                    <Accordion3 />
                </div>
            </section>
        </main>
    )
}

export default FiveHundred