const ptStrings = {
    fr: 'Francês',
    en: 'Inglês',
    pt: 'Português',
    appleText: 'AppStore',
    googleText: 'GooglePlay',
    availableApple: 'Disponível na',
    availableGoogle: 'Baixar na',
    language: 'Idioma do site',
    initials: 'PT',
    tools: 'Ferramentas',
    coin: 'iØn Coin',
    doc: 'Documentação',
    price: 'Valor',
    headerSubTitle: 'Muito além de um app, um novo conceito.',
    headerTitle: 'Uma experiência única ao',
    headerTitleAccent: 'gerenciar sua vida.',
    headerDesc: 'Ganhe tempo usando nosso app e faça parte desse novo conceito. Encontre nas plataformas GooglePlay e AppStore.',
    onePeople: 'Uma pessoa',
    towPeople: 'Duas pessoas',
    hundred: '100 mil downloads',
    fiveHundred: '500 mil downloads',
    million: '1 milhão downloads',
    hundredTitleBold: 'R$50 MIL',
    fiveHundredTitleBold: 'R$100 MIL',
    millionTitleBold: 'R$1 MILHÃO',
    hundredTitle: 'EM SUAS FINANÇAS PESSOAIS',
    fiveHundredTitle: 'EM SEU NEGÓCIO OU IDEIA',
    millionTitle: 'EM SUAS FINANÇAS PESSOAIS',
    hundredDesc: 'A cada 100 mil vendas, investiremos R$50 mil reais em suas finanças pessoais para você alavancar seus investimentos.',
    fiveHundredDesc: 'A cada 500 mil vendas, investiremos R$100 mil reais na sua empresa ou em alguma ideia de negócio que você deseja abrir.',
    millionDesc: 'A cada 1 milhão em vendas, investiremos R$1 milhão de reais em suas finanças pessoais, assim você aumentará seus investimentos.',
    benefitsTerms: 'Ver termos e condições',
    priceTitle: 'Evolução da plataforma',
    priceSubtitle: 'Junte-se a nós hoje mesmo!',
    priceTime: 'Até a proxima versão',
    priceModel: 'Modelo',
    priceLifetime: '/uso vitalício',
    priceDesc: 'O uso é vitalício e você receberá todas as atualizações ao longo do tempo',
    priceNext: 'Próxima versão',
    priceDev: 'Em desenvolvimento',
    priceSoon: 'Chegará a qualquer momento',
    priceOld: 'Versões anteriores',
    menuLearn: 'Meus cursos',
    menuLaw: 'Mundo jurídico',
    menuAffiliate: 'Programa partner',
    menuCoin: 'Moedas criptomoedas',
    toolTask: 'Organize suas tarefas',
    toolFinance: 'Orçamentos e compras',
    toolBudget: 'Minhas finanças',
    toolCalculator: 'Calculadora de juros',
    toolLaw: 'Mundo jurídico',
    toolAffiliate: 'Programa afiliados',
    toolCloud: 'Dados em nuvens',
    toolIA: 'Inteligência artificial',
    toolPodcast: 'Podcast e stories',
    toolLearn: 'Cursos e formações',
    toolMedia: 'Comunidade digital',
    toolShop: 'Shop e produtos',
    toolCoin: 'Moeda e crypto',
    toolGraphic: 'Gráficos das cryptos',
    toolBuy: 'Comprar (IØN) Coin',
    toolBank: 'Banco digital',
    toolInfest: 'Investimentos',
    toolNomad: 'Nomad',
    toDoTitle: 'Principais ferramentas',
    toDoSubtitle: 'O que você gostaria de fazer?',
    toDoTask: 'Organizar tarefas',
    toDoFinance: 'Minhas finanças',
    toDoBudget: 'Orçamentos e compras',
    toDoCalculator: 'Calculadora de juros',
    coinTitle: 'ZIYØN COIN',
    coinSubtitle: 'Uma moeda que redefine o conceito de dinheiro e abre as portas para um mundo de infinitas possibilidades. Com seu conceito global ela ultrapassa barreiras e surge para mudar o rumo da história.',
    coinTransfer: 'Transações rápidas',
    coinTransferDesc: 'Velocidade e eficiência nas transações,  permitindo transferências instantâneas em todo o mundo, sem intermediários.',
    coinSociety: 'Comunidade engajada',
    coinSocietyDesc: 'Oferecendo ótimas oportunidades de networking, aprendizado e colaboração com pessoas de interesses semelhantes.',
    coinGraphic: 'Potencial de valorização',
    coinGraphicDesc: 'Deflacionária e exclusiva, limitada a 100M, lastreado em ouro físico. Valorize seu poder com a solidez do metal mais seguro.',
    affilierLeftOne: 'Viva da nossa plataforma! Isso',
    affilierLeftTwo: 'é possível',
    affilierLeftThree: 'com nosso programa ',
    affilierLeftFour: 'partner',
    affilierDesc: 'A cada meta atingida uma valorização no seu bolso, quanto maior o valor, maior a comissão',
    affilierRightOne: 'Embarque em nosso conceito e alavanque',
    affilierRightTwo: 'seu patrimônio',
    affilierRightThree: 'através do programa',
    descBrand: 'Uma ótima solução para gerenciar sua vida, indo além de um aplicativo, um estilo de vida. Aqui temos gestão financeira, notas e lembretes, listas de compras, orçamentos, calculadora de juros, cotações de moedas, cursos e orientações jurídicas, e isso é apenas o começo. Viva neste ecossistema ou dele com nosso programa partner.',
    policies: 'Políticas',
    policiesOne: '100 mil vendas',
    policiesTwo: '500 mil vendas',
    policiesThree: '1 milhão vendas',
    policiesFour: 'Termo de uso',
    policiesFive: 'Reembolso',
    contact: 'Contatos'
};

export default ptStrings;