import React from 'react'

const Accordion1 = () => (
    <p>O Passaporte é um sistema de identificação única e segura criado exclusivamente para os clientes de nossa plataforma. Combinando um conjunto de números e letras gerados a partir dos dados do cliente, ele se torna uma chave de acesso personalizada que oferece benefícios exclusivos e autenticação robusta. É uma forma altamente segura de autenticação, garantindo que apenas os titulares autorizados possam acessar nossa plataforma e usufruir dos serviços e produtos oferecidos. Ao utilizar o Passaporte, nossos clientes poderão desfrutar de uma experiência personalizada, oferecendo-lhes acesso a recursos adicionais e aquisição de nossos produtos com facilidade e conveniência. É importante ressaltar que, para a primeira versão do nosso aplicativo, o uso do Passaporte não será necessário, permitindo que os clientes aproveitem todos os recursos básicos disponíveis sem nenhum impedimento.</p>
)

const Accordion2 = () => (
    <p>O Passaporte é uma identificação única e indispensável para nossos clientes, conferindo acesso exclusivo aos nossos produtos, serviços e plataformas. Sua utilização será crucial para o serviço de afiliado, garante benefícios adicionais aos clientes que o empregarem em lojas externas, onde será aceito como meio de obtenção de bônus e descontos especiais. Combinando segurança avançada, personalização e praticidade, o Passaporte fortalece a fidelidade dos clientes, proporcionando uma experiência singular e recompensadora em nosso ecossistema.</p>
)

const Accordion3 = () => (
    <p>O Passaporte é um recurso exclusivo e restrito a clientes que adquirem nosso aplicativo ou a dependentes de clientes que efetuaram a compra. Para criar esse passaporte, implementamos um fluxo de registro específico em nosso aplicativo, garantindo que o processo seja transparente, seguro e realizado estritamente dentro do nosso ambiente. A compra e o download do app se tornam requisitos obrigatórios para a criação do passaporte, assegurando que apenas os usuários autenticados possam usufruir dos benefícios exclusivos, serviços e produtos oferecidos em nossa plataforma. Dessa forma, o Passaporte representa uma abordagem integral e singular para proteger a segurança e a privacidade de nossos clientes, ao mesmo tempo em que os recompensamos com uma experiência personalizada e única ao utilizar nosso app.</p>
)

const Accordion4 = () => (
    <p>
        O Passaporte é um mecanismo de identificação exclusivo que concede acesso a diversos benefícios, bonificações e serviços exclusivos em nossa plataforma. No entanto, é importante salientar que, caso ocorra qualquer suspeita de fraude ou violação de nossos termos, condições ou políticas, o passaporte poderá ser bloqueado como medida de segurança e proteção aos nossos clientes e à integridade de nossos serviços.
        <br /><br />Embora o bloqueio do passaporte restrinja o acesso a recursos avançados, as ferramentas básicas da aplicação continuarão disponíveis para os clientes normalmente. No entanto, benefícios adicionais, como o Programa de Afiliados, Dados em Nuvem, Comunidade Digital, Cursos e Formações, Podcasts e Stories, Moeda e Crypto, Banco Digital, Cartão de Crédito, Shop e Leilão entre outros, estarão indisponíveis durante o período de bloqueio.
        <br /><br />Nesse contexto, caso o bloqueio seja efetuado devido a irregularidades ou violações, quaisquer valores, ativos ou investimentos vinculados ao passaporte serão devidamente reembolsados ao cliente. É fundamental ressaltar que a retomada do acesso a esses serviços e benefícios exclusivos pode estar sujeita a multas ou processos de verificação adicionais, sendo que a reversão do bloqueio pode ocorrer somente após a devida regularização da situação.
        <br /><br />Ademais, em algumas circunstâncias, o bloqueio pode ser temporário, podendo ser resolvido mediante a apresentação de documentos, assinaturas ou permissões pendentes. No entanto, em casos mais graves ou recorrentes, o bloqueio pode se tornar irreversível, implicando em uma suspensão permanente dos benefícios ou até mesmo o acesso exclusivo da conta.
        <br /><br />O bloqueio do Passaporte, embora seja uma medida de segurança vital para proteger nossos clientes e a integridade da plataforma, é uma situação que pretendemos evitar ao máximo. Por isso, incentivamos nossos clientes a agirem sempre de acordo com nossos termos e condições, garantindo que utilizem os serviços e benefícios de forma ética, responsável e em total conformidade com as políticas estabelecidas.
        <br /><br />Acreditamos que, ao zelar pela segurança de nossos usuários e pela qualidade de nossos serviços, podemos proporcionar uma experiência confiável e enriquecedora a todos os que utilizam o Passaporte.
    </p>
)

export { Accordion1, Accordion2, Accordion3, Accordion4 }